/**
 * Created by Sergey Panpurin on 5/31/2018.
 */

(function btVoiceAssistantSettingsControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btVoiceAssistantSettingsController', btVoiceAssistantSettingsController);

  btVoiceAssistantSettingsController.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    'btVoiceAssistantHelperService',
    'btShareScopeService',
  ];

  /**
   * Controller for voice assistant settings page.
   *
   * @ngdoc controller
   * @name ecapp.btVoiceAssistantSettingsController
   * @param {*} $scope - controller scope
   * @param {ecapp.ICustomRootScope} $rootScope - root scope
   * @param {angular.ITimeoutService} $timeout - timeout wrapper
   * @param {ecapp.IVoiceAssistantHelperService} btVoiceAssistantHelperService - voice assistant helper service
   * @param {ecapp.IShareScopeService} btShareScopeService - user settings service
   */
  function btVoiceAssistantSettingsController(
    $scope,
    $rootScope,
    $timeout,
    btVoiceAssistantHelperService,
    btShareScopeService
  ) {
    if (gDebug) console.log('Running btVoiceAssistantSettingsController');

    $scope.$on('$destroy', onDestroy);

    var gDebug = false;

    $scope.isEnable = false;
    $scope.isTweetReadable = false;
    $scope.icon = 'ion-android-volume-off';
    $scope.hasError = false;
    $scope.volume = {
      value: btVoiceAssistantHelperService.volumeNameToId(btVoiceAssistantHelperService.getVolumeLevel()),
    };

    $scope.speechSynthesis = {};
    $scope.status = null;
    $scope.settings = [];

    $scope.changeStatus = changeStatus;
    $scope.changeTweetReadable = changeTweetReadable;
    $scope.changeConfiguration = changeConfiguration;
    $scope.selectVoice = selectVoice;
    $scope.readTestMessage = readTestMessage;

    $scope.$watch('status.isEnable', onStatusChange);

    $scope.$watch('volume.value', onVolumeChange);

    activate();

    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);

    /**
     *
     */
    function onViewLoaded() {
      $timeout(function () {
        $('#bt-settings-voice-assistant-content').focus();
      }, 1000);
    }

    /**
     *
     */
    function onViewEnter() {
      $timeout(function () {
        $('#bt-settings-voice-assistant-content').focus();
      }, 1000);
    }

    /**
     *
     */
    function onDestroy() {
      if (gDebug) console.log('Destroy btVoiceAssistantSettingsController');
    }

    /**
     *
     * @param {*} newValue
     * @param {*} oldValue
     */
    function onStatusChange(newValue, oldValue) {
      if (newValue !== undefined && newValue !== oldValue) {
        // if (gDebug) console.log('btToggle - Settings - Watched - position:', newValue);
        $scope.isEnable = newValue;
        $scope.icon = $scope.isEnable ? 'ion-android-volume-up' : 'ion-android-volume-off';
      }
    }

    /**
     *
     * @param {*} newValue
     * @param {*} oldValue
     */
    function onVolumeChange(newValue, oldValue) {
      if (newValue !== undefined && newValue !== oldValue) {
        if (gDebug) console.log('btVoiceAssistantSettingsController:', $scope.volume.value);
        btVoiceAssistantHelperService.setVolumeLevel(btVoiceAssistantHelperService.volumeIdToName(newValue));
      }
    }

    /**
     *
     */
    function activate() {
      if (gDebug) console.log('btVoiceAssistantSettingsController: activate');
      $scope.speechSynthesis = btVoiceAssistantHelperService.getSpeechSynthesis();
      $scope.status = btVoiceAssistantHelperService.getStatus();
      $scope.isEnable = $scope.status.isEnable;
      $scope.isTweetReadable = btVoiceAssistantHelperService.getTweetReadable();
      $scope.icon = $scope.isEnable ? 'ion-android-volume-up' : 'ion-android-volume-off';
      $scope.history = btVoiceAssistantHelperService.getHistory();

      btShareScopeService
        .getAccountSettingsPromise()
        .then(btVoiceAssistantHelperService.getApplicationSettings)
        .then(function (settings) {
          if (gDebug) console.log('btVoiceAssistantSettingsController: success');
          $scope.hasError = false;
          $scope.settings = showTestSettings(settings);

          if (gDebug) console.log('btVoiceAssistantSettingsController:', JSON.stringify($scope.settings));
          if (gDebug) console.log('btVoiceAssistantSettingsController:', JSON.stringify($scope.userSettings));
          btVoiceAssistantHelperService.updateSettings($scope.settings);
          if (gDebug) console.log('btVoiceAssistantSettingsController:', JSON.stringify($scope.settings));
          if (gDebug) console.log('btVoiceAssistantSettingsController:', JSON.stringify($scope.userSettings));
        })
        .catch(function (reason) {
          console.error('btVoiceAssistantSettingsController:', reason);
          $scope.hasError = true;
          $scope.errorMessage = "Can't load settings.";
        });
    }

    /**
     *
     * @param {*} settings
     * @return {any}
     */
    function showTestSettings(settings) {
      settings.forEach(function (element) {
        element.items.forEach(function (item) {
          item.testing = $rootScope.isDevMode ? false : item.testing;
        });

        element.testing = $rootScope.isDevMode ? false : element.testing;
      });

      return settings;
    }

    /**
     *
     * @param {*} name
     * @param {*} position
     * @return {any}
     */
    function changeStatus(name, position) {
      void name;
      $scope.isEnable = position;
      toggleAssistant();

      return null;
    }

    /**
     *
     * @param {*} name
     * @param {*} position
     * @return {any}
     */
    function changeTweetReadable(name, position) {
      void name;
      void position;
      btVoiceAssistantHelperService.setTweetReadable(position);
      return null;
    }

    $scope.$on('tweetReadableChanged', function (event, data) {
      $scope.isTweetReadable = data.position;
    });

    /**
     *
     * @param {*} name
     * @param {*} position
     * @return {any}
     */
    function changeConfiguration(name, position) {
      if (gDebug) console.log('btVoiceAssistantSettingsController: changeConfiguration', name, position);
      return btVoiceAssistantHelperService.changeConfiguration(name, position);
    }

    /**
     *
     */
    function toggleAssistant() {
      if ($scope.status.isEnable) {
        btVoiceAssistantHelperService.disable();
      } else {
        btVoiceAssistantHelperService.enable().then(function () {
          $timeout(function () {
            $scope.isEnable = $scope.status.isEnable;
          });
        });
      }
    }

    /**
     *
     */
    function selectVoice() {
      btVoiceAssistantHelperService.selectVoice();
    }

    /**
     *
     */
    function readTestMessage() {
      if ($scope.status.hasVoice) {
        btVoiceAssistantHelperService.readTestMessage();
      } else {
        btVoiceAssistantHelperService.selectVoice();
      }
    }
  }
})();
