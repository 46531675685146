/**
 * Created by Orly on 09/01/2019.
 */

// @ts-check
(function btToastrServiceClosure() {
  'use strict';

  /**
   * @typedef {object} btNotification
   * @property {string} title - title text
   * @property {string} html - message in html format
   * @property {string} icon - icon identifier
   * @property {Date} time - creation time
   * @property {string} ago - elapsed time since creation (humanized)
   * @property {string} status - status (read or unread)
   * @property {?object} action - action object
   */

  /**
   * @typedef {object} btNotificationParameters
   * @property {Date} [date] - notification date
   * @property {string|*} [type] - notification type
   * @property {function|object} [onTap] - notification type
   * @property {object} [link] - link object
   */

  angular.module('ecapp').factory('btToastrService', angularService);

  angularService.$inject = ['$rootScope', '$filter', '$state', 'toastr', 'btDateService', 'btLinkService'];

  /**
   * This factory is a wrapper for Toastr.
   *
   * @ngdoc service
   * @name btToastrService
   * @memberOf ecapp
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.IFilterService} $filter
   * @param {angular.ui.IStateService} $state
   * @param {ext.IToastrService} toastr
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.ILinkService} btLinkService
   * @return {ecapp.IToastrService}
   */
  function angularService($rootScope, $filter, $state, toastr, btDateService, btLinkService) {
    /**
     * Notification counter
     * @type {Number}
     */
    var gCounter = 0;

    /**
     * List of saved notifications
     * @type {btNotification[]}
     */
    var gNotifications = [];

    /**
     * @name ecapp.btToastrService#ToastType
     * @type {Record<string, string>}
     */
    var ToastType = {
      CALENDAR: 'calendar',
      CHARTS: 'charts',
      EXCHANGE: 'exchange',
      MARKET: 'market',
      MARKET_SENSE: 'market-sense',
      MARKET_WAKEUP: 'market-wakeup',
      MARKET_WAKEUP_VOLATILITY: 'market-wakeup-volatility',
      MARKET_WAKEUP_HIGH: 'market-wakeup-high',
      MARKET_WAKEUP_LOW: 'market-wakeup-low',
      OPERATOR: 'operator',
      SYSTEM: 'system',
      SETTINGS: 'settings',
      TWEET: 'tweet',
      TRADE: 'trade',
      VOICE_ASSISTANT: 'voice-assistant',
      LEVELS: 'levels',
      MARKET_ALERTS: 'market-alerts',
      RISK: 'risk',
    };

    var gIcons = {
      calendar: 'ion-android-calendar',
      charts: undefined,
      exchange: undefined,
      market: 'ion-ios-pulse',
      'market-sense': 'ion-ios-glasses',
      'market-wakeup': 'ion-flag',
      'market-wakeup-volatility': 'ion-flag',
      'market-wakeup-high': 'ion-ios-arrow-up',
      'market-wakeup-low': 'ion-ios-arrow-down',
      operator: undefined,
      system: 'ion-settings',
      settings: 'ion-settings',
      tweet: 'ion-social-twitter',
      trade: 'ion-pricetags',
      'voice-assistant': 'ion-settings',
      levels: 'ion-drag',
      'market-alerts': 'ion-ios-pulse',
      risk: 'ion-compass',
    };

    var MAX_SIZE = 200;

    /*
     * Types of notifications:
     *   calendar              - notifications connected to calendar events
     *   charts                - notifications connected to charts modifications
     *   exchange              - notifications connected to exchanges
     *   market                - notifications connected to market movements and changes
     *     &-sense             - notifications connected to ?
     *     &-wakeup            - notifications connected to ?
     *     &-wakeup-volatility - notifications connected to ?
     *     &-wakeup-high       - notifications connected to ?
     *     &-wakeup-low        - notifications connected to ?
     *     &-alerts            - notifications connected to market alerts
     *   operator              - notifications connected to BetterTrader Operator
     *   system                - notifications connected to notification center load and cleaning
     *   settings              - notifications connected to email preferences, subscription updates and other settings
     *   tweet                 - notifications connected to new tweets
     *   trade                 - notifications connected to trade updates
     *   voice-assistant       - notifications connected to voice assistant
     */

    loadStoredNotifications();
    add('Here you will see all the notifications being received since the last system load', 'System load', {
      type: 'system',
    });

    return {
      ToastType: ToastType,
      add: add,
      info: info,
      success: success,
      warning: warning,
      error: error,
      list: list,
      clear: clear,
      read: read,
      refresh: refresh,
      open: open,
      remove: remove,
    };

    /**
     *
     * @return {boolean}
     */
    function loadStoredNotifications() {
      // var text = localStorage.getItem('btNotificationStorage');
      // try {
      //   if (text) {
      //     gNotifications = JSON.parse(text);
      //     gCounter = gNotifications[0].id + 1;
      //     return true;
      //   } else {
      //     return false;
      //   }
      // } catch (e) {
      // }
      return false;
    }

    /**
     * This function show information notification.
     *
     * @ngdoc method
     * @alias ecapp.btToastrService#add
     * @param {string} text - notification text
     * @param {string} title - notification title
     * @param {btNotificationParameters} [params] - toastr notification parameters
     */
    function add(text, title, params) {
      refresh();
      var defaults = {};
      insert('ion-information-circled', title, text, extentParameters(params, defaults));
    }

    // /**
    //  *
    //  * @param {*} docs
    //  */
    // function addMany(docs) {
    //   docs.forEach(function (doc) {
    //     insert('ion-information-circled', doc.title, doc.text, doc.params);
    //   });
    //   refresh();
    //   gNotifications.sort(function (a, b) {
    //     return a.time - b.time;
    //   })
    // }

    /**
     * Removes toastr.
     *
     * @param {ext.IToast} item - toastr
     */
    function remove(item) {
      toastr.remove(item.toastId);
    }

    /**
     * This function show information notification
     *
     * @ngdoc method
     * @alias ecapp.btToastrService#info
     * @param {string} text - notification text
     * @param {string} title - notification title
     * @param {ecapp.IToastrOptions} [params] - toastr notification parameters
     * @return {ext.IToast}
     */
    function info(text, title, params) {
      refresh();
      var defaults = {};
      if (!params || !params.hidden) {
        insert('ion-information-circled', title, text, extentParameters(params, defaults));
      }
      return toastr.info(text, title, extentParameters(params, defaults));
    }

    /**
     * This function show success notification.
     *
     * @ngdoc method
     * @alias ecapp.btToastrService#success
     * @param {String} text - notification text
     * @param {String} title - notification title
     * @param {ecapp.IToastrOptions} [params] - toastr notification parameters
     * @return {ext.IToast}
     */
    function success(text, title, params) {
      refresh();
      var defaults = {};
      if (!params || !params.hidden) {
        insert('ion-checkmark-circled', title, text, extentParameters(params, defaults));
      }
      return toastr.success(text, title, extentParameters(params, defaults));
    }

    /**
     * This function show warning notification.
     *
     * @ngdoc method
     * @alias ecapp.btToastrService#warning
     * @param {String} text - notification text
     * @param {String} title - notification title
     * @param {ecapp.IToastrOptions} [params] - toastr notification parameters
     * @return {ext.IToast}
     */
    function warning(text, title, params) {
      refresh();
      var defaults = { timeOut: 10000, extendedTimeOut: 5000 };
      if (!params || !params.hidden) {
        insert('ion-alert-circled', title, text, extentParameters(params, defaults));
      }
      return toastr.warning(text, title, extentParameters(params, defaults));
    }

    /**
     * This function show warning notification
     *
     * @ngdoc method
     * @alias ecapp.btToastrService#error
     * @param {String} text - notification text
     * @param {String} title - notification title
     * @param {ecapp.IToastrOptions} [params] - toastr notification parameters
     * @return {ext.IToast}
     */
    function error(text, title, params) {
      refresh();
      var defaults = { timeOut: 30000, extendedTimeOut: 5000 };
      if (!params || !params.hidden) {
        insert('ion-close-circled', title, '[Error] ' + text, extentParameters(params, defaults));
      }
      return toastr.error(text, title, extentParameters(params, defaults));
    }

    /**
     *
     * @param {ecapp.IToastrOptions} params
     * @param {ecapp.IToastrOptions} defaults
     * @return {ecapp.IToastrOptions}
     */
    function extentParameters(params, defaults) {
      if (params && typeof params.onTap !== 'function' && typeof params.link === 'object' && params.link.state) {
        params.onTap = function () {
          $state.go(params.link.state, params.link.params || {});
        };
      }
      return angular.extend(defaults, params);
    }

    /**
     * This function returns list of saved notifications.
     *
     * @ngdoc method
     * @alias ecapp.btToastrService#list
     * @return {btNotification[]}
     */
    function list() {
      return gNotifications;
    }

    /**
     * This function deletes all saved notifications.
     *
     * @ngdoc method
     * @alias ecapp.btToastrService#clear
     */
    function clear() {
      /* Alternative
      while(gNotifications.length) {
        gNotifications.pop();
      }
      */
      gNotifications.length = 0;
      add("Notification's record starting at  " + $filter('date')(Date.now(), 'HH:mm'), 'System clean', {
        type: 'system',
      });
    }

    /**
     * This function marks all saved notifications as read.
     *
     * @ngdoc method
     * @alias ecapp.btToastrService#read
     */
    function read() {
      gNotifications.forEach(function (value) {
        value.status = 'read';
      });
    }

    /**
     * This function recalculates how much time has passed from receiving saved notifications
     * @ngdoc method
     * @alias ecapp.btToastrService#refresh
     */
    function refresh() {
      gNotifications.forEach(function (notification) {
        notification.ago = btDateService.getHumanisedTimeFromNow(notification.time, true);
      });
    }

    /**
     * This function generates new notification object.
     * Next parameters are used:
     *  - type: type of notification (it is used to select right icon)
     *  - onTap: on tap action
     *
     *
     * @param {string} icon - icon identifier (can be overridden by params.iconClass)
     * @param {string} title - title text
     * @param {string} text - message in html format
     * @param {ecapp.IToastrOptions} [params] - toastr parameters
     * @return {btNotification}
     */
    function newNotification(icon, title, text, params) {
      var notification = {
        id: gCounter++,
        html: text,
        title: title,
        icon: icon,
        time: params && params.date ? params.date : new Date(),
        ago: 'a second ago',
        status: 'unread',
        action: null,
      };

      if (params) {
        notification.icon = overrideIcon(icon, params.type);
        if (params.onTap) {
          notification.action = { type: 'tap', onTap: params.onTap };
        }
      }

      return notification;
    }

    /**
     * This function reacts on user click on notification
     *
     * @ngdoc method
     * @alias ecapp.btToastrService#open
     * @param {btNotification} notification - notification object
     */
    function open(notification) {
      refresh();
      // mark as read
      notification.status = 'read';

      // try to open link or run onTap function
      if (notification.action) {
        switch (notification.action.type) {
          case 'external-link':
            btLinkService.openExternal(notification.action.url);
            // window.open(notification.action.url);
            break;
          case 'internal-link':
            btLinkService.openInternal(notification.action.state, notification.action.params);
            // $state.go(notification.action.state, notification.action.params);
            break;
          case 'tap':
            notification.action.onTap();
            break;
          default:
            console.error('btNotificationCenter: Unknown action type', notification.action.type);
        }
      }
    }

    /**
     * Set notification icon
     *
     * @param {string} defaultIcon -
     * @param {string} type -
     * @return {string}
     */
    function overrideIcon(defaultIcon, type) {
      if (gIcons[type]) {
        return gIcons[type];
      } else {
        return defaultIcon;
      }
    }

    /**
     *
     * @param {*} icon
     * @param {*} title
     * @param {*} text
     * @param {*} params
     */
    function insert(icon, title, text, params) {
      var notification = newNotification(icon, title, text, params);
      gNotifications.unshift(notification);
      if (gNotifications.length > MAX_SIZE) gNotifications.pop();

      $rootScope.$emit('notification:inserted', notification);
      // localStorage.setItem('btNotificationStorage', JSON.stringify(gNotifications));
    }
  }
})();
