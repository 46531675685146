/**
 * Created by Orly on 05/03/2019.
 */

(function btTwitterScannerServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btTwitterScannerService';

  angular.module('ecapp').factory('btTwitterScannerService', service);

  service.$inject = [
    '$q',
    '$rootScope',
    '$templateCache',
    '$ionicModal',
    '$ionicPopup',
    'btSettings',
    'btShareScopeService',
    'btToastrService',
    'btSpeechService',
    'btRestrictionService',
    'btTwitterService',
  ];

  /**
   * This function is a implementation of btTwitterScannerService
   *
   * @param {angular.IQService} $q
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ionic.IModalService} $ionicModal
   * @param {ionic.IPopupService} $ionicPopup
   * @param {ecapp.ISettings} btSettings
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.ISpeechService} btSpeechService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ITwitterService} btTwitterService
   * @return {ecapp.ITwitterScannerService}
   */
  function service(
    $q,
    $rootScope,
    $templateCache,
    $ionicModal,
    $ionicPopup,
    btSettings,
    btShareScopeService,
    btToastrService,
    btSpeechService,
    btRestrictionService,
    btTwitterService
  ) {
    /**
     * @ngdoc service
     * @name btTwitterScannerService
     * @memberOf ecapp
     * @description
     *  This service implements twitter scanner feature.
     *
     *  Twitter Scanner gives user ability to customize Voice Assistant to tracks Twitter accounts selected by user.
     *  All settings are stored in user.settings.customTwitterChannels. Twitter Scanner doesn't handler pusher  response for following
     *  settings.
     *  it's a part of Voice Assistant.
     *
     *  Twitter Scanner expects that user is already initialized.
     *
     *  Twitter channels are stored in db this way:
     *   - CustomTwitterChannels - list of all added custom twitter channels
     *   - VoiceAssistant.twitter.items - list af followed custom twitter channels
     */

    /**
     * The User object contains public Twitter account metadata and describes the of the Tweet.
     *
     * @see {@link https://developer.twitter.com/en/docs/tweets/data-dictionary/overview/user-object Twitter Documentation}
     * @typedef {object} TwitterUser
     * @property {string} id_str - stringified channel's id
     * @property {string} name - full channel's name
     * @property {string} screen_name - name after @ sign
     * @property {string} description - channel description
     * @property {string} profile_image_url_https - https-url of the avatar
     * @property {string} profile_image_url - http-url of the avatar
     * @property {boolean} verified - account is verified by twitter
     * @property {number} followers_count - count of followers
     * @property {number} statuses_count - count of tweets and retweets
     */

    /**
     * Information about user customization of twitter account stored in user settings.
     *
     * This information can be changed to user to customize representation of twitter account
     *
     * @see btTwitterAccount
     * @typedef {Object} btCustomTwitterAccount
     * @property {string} id - stringified channel's id
     * @property {string} name - name after @ sign
     * @property {string} displayName - full channel's name
     * @property {string} pronunciation - pronunciation of the channel's name
     * @property {string} [enable] - channel is enabled
     * @property {string} [testing] - channel is in testing
     * @property {string} [description] - channel's description
     * @property {string} [params] - channel's parameters
     * @property {*} [timeline] - timeline widget
     */

    if (gDebug) console.log('Running btTwitterScannerService');
    /**
     * These settings are shared with btVoiceAssistantHelperService. It use modify them to prepare application settings.
     * @type {btCustomTwitterAccount[]}
     */
    var gCustomTwitterChannels = [];

    /**
     * This is a list of demo accounts.
     * @type {string[]}
     */
    var gDemoAccounts = [];

    /**
     * These are a suggested custom accounts loaded from database.
     * @type {?TwitterUser[]}
     */
    var gDefaultCustomTwitterChannels = null;

    var gSearchScope = null;

    /**
     * @type {ecapp.btTwitterScannerService}
     */
    return {
      initialize: initialize,
      addNewChannel: addNewChannel,
      getTotalLimit: getTotalLimit,

      list: getCustomChannels,
      findByName: getChannelByName,
      findById: getChannelById,
      add: addTwitterChannel,
      remove: removeTwitterChannel,
      update: updateTwitterChannel,
      follow: startFollowing,
      unfollow: stopFollowing,
      isDemo: isDemo,
      search: search,
      edit: edit,
    };

    /**
     * This function initializes btTwitterScannerService.
     *
     * Note: Require error handler
     *
     * @public
     * @alias ecapp.btTwitterScannerService#initialize
     * @return {angular.IPromise<TwitterUser[]>}
     */
    function initialize() {
      return getDefaultAccounts();
    }

    /**
     *
     * @alias ecapp.btTwitterScannerService#getTotalLimit
     * @return {number}
     */
    function getTotalLimit() {
      return 10;
    }

    /**
     * This function loads default accounts from database, prepare custom channels and save demo accounts.
     *
     * Note: This function uses in memory cache.
     *
     * @return {angular.IPromise<TwitterUser[]>}
     */
    function getDefaultAccounts() {
      if (gDefaultCustomTwitterChannels) {
        return $q.resolve(gDefaultCustomTwitterChannels);
      } else {
        return btShareScopeService
          .getTwitterAccounts()
          .then(recoverCustomChannels)
          .then(saveDemoAccounts)
          .then(function (serverAccounts) {
            // Load twitter data for suggested accounts
            return $q.all(serverAccounts.sort(compareAccountPriority).map(getChannelInformation));
          })
          .then(function (accounts) {
            gDefaultCustomTwitterChannels = accounts.filter(isValidAccount);
            return gDefaultCustomTwitterChannels;
          });
      }
    }

    /**
     * This function check custom channels and recovers them if necessary.
     *
     * @param {btCustomTwitterAccount[]} suggestedAccounts
     * @return {angular.IPromise<btCustomTwitterAccount[]>}
     */
    function recoverCustomChannels(suggestedAccounts) {
      var userChannels = btShareScopeService.getUserSettings('customTwitterChannels');

      if (userChannels) {
        gCustomTwitterChannels = userChannels;
      } else {
        // If user don't have custom channels. Try to recover them.
        var followedChannels = getFollowedChannels();

        if (followedChannels) {
          // If user have voice assistant settings try to use them as a reference.
          gCustomTwitterChannels = followedChannels
            .map(function (channel) {
              return suggestedAccounts.filter(function (account) {
                return account.name === channel;
              })[0];
            })
            .filter(function (value) {
              return !!value;
            });
        } else {
          // Otherwise just show default channels
          gCustomTwitterChannels = suggestedAccounts;
        }

        saveUserSettings();
      }

      return $q.resolve(suggestedAccounts);
    }

    /**
     * This function saves demo accounts.
     *
     * @param {btTwitterAccount[]} accounts
     * @return {angular.IPromise<btTwitterAccount[]>}
     */
    function saveDemoAccounts(accounts) {
      gDemoAccounts = accounts.filter(isDemoAccount).map(function (account) {
        return account.name;
      });

      if (gDebug) console.log(gPrefix, 'demo accounts:', gDemoAccounts);

      return $q.resolve(accounts);
    }

    /**
     * This function converts gCustomTwitterChannels and saves it to the database.
     *
     * @return {angular.IPromise<*>}
     */
    function saveUserSettings() {
      var settings = gCustomTwitterChannels.map(function (setting) {
        return {
          name: setting.name,
          id: setting.id,
          displayName: setting.displayName,
          pronunciation: setting.pronunciation,
        };
      });

      return btShareScopeService.saveUserSettings('customTwitterChannels', settings);
    }

    /**
     * This functions parses voice assistant settings of user to get list of followed channels.
     *
     * @return {string[]}
     */
    function getFollowedChannels() {
      var settings = btShareScopeService.getUserSettings('voiceAssistant');

      if (settings && settings.twitter && settings.twitter.items) {
        return settings.twitter.items;
      } else {
        return null;
      }
    }

    /**
     * This function returns custom channels.
     *
     * @public
     * @alias ecapp.btTwitterScannerService#list
     * @return {btCustomTwitterAccount[]}
     */
    function getCustomChannels() {
      return gCustomTwitterChannels;
    }

    /**
     * This function returns suggested channels.
     *
     * @return {?TwitterUser[]}
     */
    function getDefaultCustomTwitterChannels() {
      return gDefaultCustomTwitterChannels;
    }

    /**
     * This function checks whether account is demo.
     *
     * @param {btTwitterAccount} account - selected account
     * @return {boolean}
     */
    function isDemoAccount(account) {
      return account.params.type === 'demo';
    }

    /**
     * This function compares two account by priority.
     *
     * @param {btTwitterAccount} a - first account
     * @param {btTwitterAccount} b - seconds account
     * @return {number}
     */
    function compareAccountPriority(a, b) {
      return a.params.priority - b.params.priority;
    }

    /**
     * This function gets twitter data about channel.
     *
     * @param {btTwitterAccount} account - selected account
     * @return {angular.IPromise<any>}
     */
    function getChannelInformation(account) {
      return btTwitterService.show(account.name).catch(function (reason) {
        console.error(reason);
        return null;
      });
    }

    /**
     * This function check whether account is not null.
     *
     * @param {?btTwitterAccount} account - selected account
     * @return {boolean}
     */
    function isValidAccount(account) {
      return !!account;
    }

    /**
     * This function shows modal window to search channels or upgrade banner.
     *
     * Note: Require error handler
     *
     * @public
     * @alias ecapp.btTwitterScannerService#addNewChannel
     * @return {angular.IPromise<?btCustomTwitterAccount>}
     * @throws FullTwitterScannerError, DuplicationTwitterScannerError
     */
    function addNewChannel() {
      if (allowedAdding()) {
        return search()
          .then(function (channel) {
            if (channel) return addTwitterChannel(channel);
            else return null;
          })
          .catch(function (reason) {
            return $q.reject(reason);
          });
      } else {
        // max amount of channels has been added to the list
        return requireUpgrade('add').then(function () {
          return $q.reject(new Error('Twitter Scanner is full!'));
        });
      }
    }

    /**
     * This function adds channel to Twitter Scanner.
     *
     * @throws DuplicationTwitterScannerError
     * @param {btCustomTwitterAccount} channel - selected channel
     * @return {angular.IPromise<btCustomTwitterAccount>}
     */
    function addTwitterChannel(channel) {
      if (!!getChannelByName(channel.name)) {
        btToastrService.error('This channel is already in your list', 'Failed to add channel');
        return $q.reject(new Error('This channel is already in your list'));
      }

      if (allowedAdding()) {
        // Adding new channel to the list
        gCustomTwitterChannels.push(channel);
        return saveUserSettings().then(function () {
          return channel;
        });
      } else {
        btToastrService.error('You have reached twitter scanner limit', 'Failed to add channel');
        return $q.reject(new Error('You have reached twitter scanner limit'));
      }
    }

    /**
     * This function returns channel by name (after @ sign).
     *
     * @public
     * @alias ecapp.btTwitterScannerService#findByName
     * @param {string} name - channel name
     * @return {btCustomTwitterAccount|undefined}
     */
    function getChannelByName(name) {
      // noinspection JSUnresolvedFunction
      return gCustomTwitterChannels.find(function (c) {
        return c.name === name;
      });
    }

    /**
     * This function returns channel by identifier.
     *
     * @public
     * @alias ecapp.btTwitterScannerService#findById
     * @param {string} id - channel identifier
     * @return {btCustomTwitterAccount|undefined}
     */
    function getChannelById(id) {
      return gCustomTwitterChannels.find(function (c) {
        return c.id === id;
      });
    }

    /**
     * This function returns index of first channel with specified name in list.
     *
     * @param {string} name - account name
     * @return {number|undefined}
     */
    function getChannelIndexByName(name) {
      var index = undefined;
      gCustomTwitterChannels.some(function (c, i) {
        if (c.name === name) {
          index = i;
          return true;
        }
        return false;
      });
      return index;
    }

    /**
     * This function returns index of first channel with specified identifier in list.
     *
     * @param {string} id - account identifier
     * @return {number|undefined}
     */
    function getChannelIndexById(id) {
      var index = undefined;
      gCustomTwitterChannels.some(function (c, i) {
        if (c.id === id) {
          index = i;
          return true;
        }
        return false;
      });
      return index;
    }

    /**
     * This function updates Twitter Scanner.
     *
     * @public
     * @alias ecapp.btTwitterScannerService#update
     * @param {btCustomTwitterAccount} channel - channel object
     * @return {angular.IPromise<btCustomTwitterAccount>}
     */
    function updateTwitterChannel(channel) {
      if (gDebug) console.log('btTwitterScannerService: gCustomTwitterChannels', gCustomTwitterChannels);
      return saveUserSettings().then(function () {
        return channel;
      });
    }

    /**
     * This function removes channel from the list.
     *
     * @public
     * @alias ecapp.btTwitterScannerService#remove
     * @param {string} name - name of the channel (after @ sign)
     * @return {angular.IPromise<boolean>}
     * @throws UndefinedUser, ServerError, ChannelNotFound
     */
    function removeTwitterChannel(name) {
      var followedChannel = getChannelByName(name);

      return stopFollowing(followedChannel).then(function () {
        //Stops follow channel before removing
        var index = getChannelIndexByName(name);
        if (index !== undefined) {
          gCustomTwitterChannels.splice(index, 1);
          return saveUserSettings().then(function () {
            return true;
          });
        } else {
          return $q.reject(new Error('Channel was not found.'));
        }
      });
    }

    /**
     * This function starts following channel.
     *
     * @public
     * @alias ecapp.btTwitterScannerService#follow
     * @param {btCustomTwitterAccount} channel - channel object
     * @return {angular.IPromise<boolean>}
     * @throws UpgradeRequired
     */
    function startFollowing(channel) {
      if (canBeFollowed(channel.name)) {
        return btTwitterService.add(channel.name);
      } else {
        // User is required to upgrade the account
        return requireUpgrade('follow').then(function (value) {
          if (value === 'updated') {
            return btTwitterService.add(channel.name);
          } else {
            return $q.reject(new Error('Upgrade is required!'));
          }
        });
      }
    }

    /**
     * This function checks whether channel can be followed according to subscription restrictions.
     *
     * @param {string} name - channel name
     * @return {boolean}
     */
    function canBeFollowed(name) {
      return (
        btRestrictionService.hasFeature('voice-assistant') &&
        (isDemo(name) || countFollowedChannels() < btRestrictionService.getCapacity('custom-twitter'))
      );
    }

    /**
     * This function stops following channel. It should return true on success.
     *
     * @public
     * @alias ecapp.btTwitterScannerService#unfollow
     * @param {btCustomTwitterAccount} channel - channel object
     * @return {angular.IPromise<boolean>}
     * @throws UndefinedUser | ServerError
     */
    function stopFollowing(channel) {
      return btTwitterService.remove(channel.name);
    }

    /**
     * This function checks whether adding of a new channel is allowed according to subscription restrictions.
     *
     * @return {boolean}
     */
    function allowedAdding() {
      return gCustomTwitterChannels.length < btRestrictionService.getCapacity('total-twitter');
    }

    /**
     * This function checks whether channel is demo.
     *
     * @public
     * @alias ecapp.btTwitterScannerService#isDemo
     * @param {string} name - channel screen name
     * @return {boolean}
     */
    function isDemo(name) {
      if (gDebug) console.log(gPrefix, 'is', name, 'demo?', gDemoAccounts, gDemoAccounts.indexOf(name) > -1);
      return gDemoAccounts.indexOf(name) > -1;
    }

    /**
     * This function checks whether channel is not demo.
     *
     * @param {string} name - channel screen name
     * @return {boolean}
     */
    function isNotDemo(name) {
      return !isDemo(name);
    }

    /**
     * This function checks whether channel already added to twitter scanner
     *
     * @param {string} name - channel screen name
     * @return {boolean}
     */
    function isAlreadyAdded(name) {
      return getChannelByName(name) !== undefined;
    }

    /**
     * This function defines which upgrade banner to show
     *
     * @param {string} type - upgrade type: add or follow
     * @return {angular.IPromise<string>}
     */
    function requireUpgrade(type) {
      // allowed only demo channels
      if (btRestrictionService.getCapacity('custom-twitter') === 0) {
        // Free or Lite plans
        return btRestrictionService.showUpgradePopup('twitter-scanner-enable');
      }

      if (type === 'add') {
        // max amount of added channels
        return btRestrictionService.showUpgradePopup('twitter-scanner-max'); // Backtester plan
      }

      if (type === 'follow') {
        // no free slots for adding/following
        return btRestrictionService.showUpgradePopup('twitter-scanner-increase');
      }

      return btRestrictionService.showUpgradePopup('twitter-scanner-enable');
    }

    /**
     * This function counts number of followed non-demo channels
     *
     * @return {number}
     */
    function countFollowedChannels() {
      return (getFollowedChannels() || []).filter(isNotDemo).length;
    }

    /**
     * This function opens modal view to give user ability to search for twitter user
     *
     * @public
     * @alias ecapp.btTwitterScannerService#search
     * @return {angular.IPromise<btCustomTwitterAccount>}
     */
    function search() {
      var deferred = $q.defer();

      initializeSearch(deferred);

      return deferred.promise;

      /**
       *
       * @param {*} deferred
       */
      function initializeSearch(deferred) {
        if (gSearchScope === null) {
          gSearchScope = $rootScope.$new(true);

          gSearchScope.search = { text: '' };
          gSearchScope.lastSearch = null;
          gSearchScope.hasSearchResults = false;
          gSearchScope.isSearchResultsEmpty = false;
          gSearchScope.isSearchCompleted = true;

          // gSearchScope.show = show;
          gSearchScope.hide = hide;
          gSearchScope.clear = clear;
          gSearchScope.onSearch = onSearch;
          gSearchScope.onSelect = onSelect;
          gSearchScope.isDemo = isDemo;
          gSearchScope.isAlreadyAdded = isAlreadyAdded;

          var template = $templateCache.get('modals/bt-search-twitter-channel-modal.html');
          var params = {
            scope: gSearchScope,
            animation: 'slide-in-up',
            focusFirstInput: window.isDesktop,
          };

          // noinspection JSUnresolvedFunction
          gSearchScope.modal = $ionicModal.fromTemplate(template, params);
        }

        gSearchScope.resolved = false;
        gSearchScope.deferred = deferred;

        var modalHiddenDestructor = gSearchScope.$on('modal.hidden', function () {
          modalHiddenDestructor();
          if (!gSearchScope.resolved) {
            gSearchScope.deferred.resolve(null);
            gSearchScope.deferred = null;
          }
        });

        clear();

        gSearchScope.modal.show();

        /**
         * This function hides search window.
         */
        function hide() {
          gSearchScope.modal.hide();
        }

        /**
         * This function clears search.
         */
        function clear() {
          gSearchScope.search.text = '';
          onSearch(gSearchScope.search.text); //shows suggested channels
        }

        /**
         * This function reacts on search of twitter channels.
         *
         * @param {string} text - search text
         */
        function onSearch(text) {
          if (gSearchScope.lastSearch === text) return;
          gSearchScope.lastSearch = text;

          gSearchScope.isSearchCompleted = false;
          gSearchScope.isSearchResultsEmpty = false;
          gSearchScope.hasSearchResults = false;

          if (gDebug) console.log('btTwitterScannerController: onSearch', text, gSearchScope.search.text);
          if (text) {
            btTwitterService
              .search(text)
              .then(function success(users) {
                if (users.length) {
                  gSearchScope.hasSearchResults = true;
                  gSearchScope.searchedChannels = users;
                } else {
                  gSearchScope.hasSearchResults = false;
                  gSearchScope.searchedChannels = getDefaultCustomTwitterChannels();
                }
              })
              .catch(function (reason) {
                console.error(gPrefix, reason);
                btToastrService.error('Search failed. ' + (reason.message || 'Unknown error'), 'Twitter Scanner');

                gSearchScope.searchedChannels = getDefaultCustomTwitterChannels();
              })
              .finally(function () {
                gSearchScope.isSearchCompleted = true;
              });
          } else {
            gSearchScope.isSearchCompleted = true;
            gSearchScope.searchedChannels = getDefaultCustomTwitterChannels();
          }
        }

        /**
         * This function reacts on channel selection.
         *
         * @param {TwitterUser} user - twitter user object
         */
        function onSelect(user) {
          if (isAlreadyAdded(user.screen_name)) {
            btToastrService.error('This channel is already in your list', 'Failed to add channel');
            return;
          }

          var channel = {
            id: user.id_str,
            displayName: user.name,
            name: user.screen_name,
            pronunciation: user.name,
          };

          edit('add', channel)
            .then(function (modifiedChannel) {
              gSearchScope.resolved = true;
              gSearchScope.modal.hide();
              gSearchScope.deferred.resolve(modifiedChannel);
              gSearchScope.deferred = null;
            })
            .catch(function (reason) {
              // Should not be reached
              gSearchScope.modal.hide();
              gSearchScope.deferred.reject(reason);
              gSearchScope.deferred = null;
            });
        }
      }
    }

    /**
     * This function customize twitter channel
     *
     * @public
     * @alias ecapp.btTwitterScannerService#edit
     * @param {string} type - action add or edit
     * @param {btCustomTwitterAccount} channel - twitter channel
     * @return {angular.IPromise<btCustomTwitterAccount>}
     */
    function edit(type, channel) {
      var deferred = $q.defer();

      var $scope = $rootScope.$new(true);
      $scope.isBlank = false;
      $scope.channel = channel;
      $scope.popover = { displayName: channel.displayName, pronunciation: channel.pronunciation };
      $scope.action = type === 'add' ? 'Add' : 'Edit';

      $scope.pronounce = pronounce;

      $ionicPopup.show({
        template: $templateCache.get('components/mobile/bt-voice-assistant/bt-twitter-scanner-edit.html'),
        title: 'Twitter Scanner',
        scope: $scope,
        buttons: [{ text: 'Cancel' }, { text: type === 'add' ? 'Add' : 'Save', onTap: getOnTapHandler(deferred) }],
      });

      return deferred.promise;

      /**
       * This function returns buttons handler.
       *
       * @param {Q.Deferred} deferred - deferred object
       * @return {function}
       */
      function getOnTapHandler(deferred) {
        return function onTap(e) {
          if ($scope.popover.pronunciation === '' || $scope.popover.displayName === '') {
            $scope.isBlank = true;
            e.preventDefault();
          } else {
            $scope.channel.displayName = $scope.popover.displayName;
            $scope.channel.pronunciation = $scope.popover.pronunciation;
            deferred.resolve($scope.channel);
          }
        };
      }

      /**
       * This function tests pronunciation.
       *
       * @param {string} text - text to pronounce
       */
      function pronounce(text) {
        btSpeechService.pronounce('New tweet of ' + text);
      }
    }
  }
})();
