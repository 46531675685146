/**
 * Created by Sergey Panpurin on 6/11/2018.
 */

// @ts-check
(function btRestrictionServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btRestrictionService', btRestrictionService);

  btRestrictionService.$inject = [
    '$q',
    '$rootScope',
    '$location',
    '$ionicModal',
    '$ionicLoading',
    '$ionicPopup',
    '$templateCache',
    '$timeout',
    '$analytics',
    'btSubscriptionService',
    'btShareScopeService',
    'btToastrService',
    'btSettingsService',
    'btTagService',
  ];

  /**
   * This service restricts premium content.
   *
   * @ngdoc service
   * @name btRestrictionService
   * @memberOf ecapp
   * @param {angular.IQService} $q - promise interface
   * @param {ecapp.ICustomRootScope} $rootScope - a
   * @param {angular.ILocationService} $location - a
   * @param {ionic.IModalService} $ionicModal - ionic modal service
   * @param {ionic.ILoadingService} $ionicLoading - ionic loading service
   * @param {ionic.IPopupService} $ionicPopup - ionic popup service
   * @param {angular.ITemplateCacheService} $templateCache - a
   * @param {angular.ITimeoutService} $timeout - a
   * @param {ext.IAnalyticsService} $analytics - a
   * @param {ecapp.ISubscriptionService} btSubscriptionService - a
   * @param {ecapp.IShareScopeService} btShareScopeService - a
   * @param {ecapp.IToastrService} btToastrService - a
   * @param {ecapp.ISettingsService} btSettingsService - a
   * @param {ecapp.ITagService} btTagService - a
   * @return {ecapp.IRestrictionService} restriction service
   */
  function btRestrictionService(
    $q,
    $rootScope,
    $location,
    $ionicModal,
    $ionicLoading,
    $ionicPopup,
    $templateCache,
    $timeout,
    $analytics,
    btSubscriptionService,
    btShareScopeService,
    btToastrService,
    btSettingsService,
    btTagService
  ) {
    console.log('Running btRestrictionService');

    var gIsBigBrainBank = btSettingsService.getDomain() === 'bigbrainbank';

    /**
     * Subscription settings
     *
     * @type {?ecapp.btSubscriptionSettingsObject}
     */
    var gSettings = null;

    /**
     *
     * @type {*}
     */
    var gScopeCache = {};

    /**
     *
     * @type {Record<string, *>}
     */
    var gFeatureTypes = {
      'voice-assistant': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Starter plan',
          plan: 'Smart',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Voice Assistant',
        html:
          'Full audio coverage that includes:<br>' +
          '1. Macro news - economic releases with magnitude of surprise<br>' +
          '2. Market movements - trade ideas produced from live market changes<br>' +
          '3. Twitter news - real-time voice alerts based on customized Twitter News',
      },
      'twitter-scanner-enable': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Smart plan',
          plan: 'Pro',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Twitter Scanner',
        html:
          'Get instant voice and notification for live tweets from the most influential and successful traders ' +
          'in the market.<br><br>' +
          '<b>Twitter Scanner includes:</b><br>' +
          '- Twitter dashboard<br>' +
          '- Voice & Text Alerts<br>' +
          '- Suggestions for trending accounts to follow<br><br>You do not need to look at the screen. Just listen!',
      },
      'twitter-scanner-increase': {
        feature: {
          type: 'increase',
          getValue: getCustomTwitterChannelsCapacity,
          plan: 'Pro',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Get more tweets',
        html:
          'You have reached your limit of tracked accounts.<br><br><b>Expand your Twitter Scanner and ensure ' +
          'you can track up to 10 essential Twitter Accounts.',
      },
      'twitter-scanner-max': {
        feature: {
          type: 'max',
          getValue: getTotalTwitterChannelsCapacity,
          plan: 'Pro',
        },
        tag: null,
        title: 'Twitter Scanner absolute limit reached!',
        html: 'You have reached your limit of tracked accounts. Contact us if you need more!',
      },
      'tweet-reading-enable': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Mastermind' : 'Pro plan',
          plan: 'Pro',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Tweet Reading',
        html: 'Listen to the tweets from the most influential and successful traders read by Voice Assistant.<br>',
      },
      'market-sense': {
        feature: {
          type: 'increase',
          getValue: getMarketSenseCapacity,
          plan: 'Smart',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Add more MarketSense',
        html:
          'You have reached your limit of MarketSense notifications.<br><br><b>Upgrade to get more smart ' +
          'notifications that tells you when volatility increases or there is a significant move in your selected ' +
          'market.<br>Never miss an opportunity again!',
      },
      'market-sense-max': {
        feature: {
          type: 'max',
          getValue: getMarketSenseCapacity,
          plan: 'Smart',
        },
        tag: null,
        title: 'MarketSense absolute limit reached!',
        html: 'Contact us if you need more!',
      },
      'market-wakeup': {
        feature: {
          type: 'increase',
          getValue: getMarketWakeupCapacity,
          plan: 'Smart',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Add more MarketWakeup',
        html:
          'You have reached your limit of MarketSense notifications.<br><br><b>Upgrade to get more smart ' +
          'notifications that tells you when volatility increases or there is a significant move in your selected ' +
          'market.<br>Never miss an opportunity again!',
      },
      'market-wakeup-max': {
        feature: {
          type: 'max',
          getValue: getMarketWakeupCapacity,
          plan: 'Smart',
        },
        tag: null,
        title: 'MarketWakeup limit reached!',
        html: 'Contact us if you need more!',
      },
      watchlist: {
        feature: {
          type: 'increase',
          getValue: getWatchlistCapacity,
          plan: 'Smart',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Expand your Watchlist!',
        html:
          'You have reached your Watchlist limit.<br><br><b>Expand your WatchList and ensure you can track all ' +
          'of your essential market.',
      },
      'watchlist-max': {
        feature: {
          type: 'max',
          getValue: getWatchlistCapacity,
          plan: 'Smart',
        },
        tag: null,
        title: 'Watchlist limit reached!',
        html: 'Contact us if you need more!',
      },
      'follow-events': {
        feature: {
          type: 'increase',
          getValue: getFollowEventsCapacity,
          plan: 'Smart',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Expand your Follow Events',
        html:
          'You have reached your Follow Events limit.<br><br><b>Expand your Follow Events and ensure you can ' +
          'track all of your essential events.',
      },
      'follow-events-max': {
        feature: {
          type: 'max',
          getValue: getFollowEventsCapacity,
          plan: 'Smart',
        },
        tag: null,
        title: 'Contact us if you need more!',
      },
      'weekly-mails': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Starter plan',
          plan: 'Starter',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Weekly Mails!',
        html: 'You will receive weekly mails<br>',
      },
      personalization: {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Starter plan',
          plan: 'Starter',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Customization!',
        html: 'You will have ability to customize application<br>',
      },
      'calendar-settings': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Starter plan',
          plan: 'Starter',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Calendar Settings!',
        html: 'You will have ability to customize calendar<br>',
      },
      'mails-settings': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Starter plan',
          plan: 'Starter',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Mails Settings!',
        html: 'You will have ability to customize daily and weekly mail reports<br>',
      },
      'notification-settings': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Starter plan',
          plan: 'Starter',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Notifications Settings!',
        html: 'You will have ability to customize real-time push notifications<br>',
      },
      'premium-notifications': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Starter plan',
          plan: 'Starter',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Premium Notifications!',
        html: 'You will receive push notifications about perspective insights and news-driven trade ideas<br>',
      },
      'market-widgets-notifications': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Starter plan',
          plan: 'Starter',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Market Widgets Notifications!',
        html: 'You will receive push notifications of Market Sense and Market Wakeup features<br>',
      },
      'trade-ideas': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Starter plan',
          plan: 'Smart',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Trade Ideas!',
        html:
          "Trade Ideas are tested based on it's past market reaction in identical scenarios.<br><br><b>Trade " +
          'Ideas includes:</b><br>1. Entry price<br>2. Stop loss<br>3. Profit Target',
      },
      'calendar-smart-filtering': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Starter plan',
          plan: 'Smart',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Smart Filtering!',
        html:
          'Filter the calendar based on trade ideas that are relevant to your WatchList.<br><br>' +
          '1. See only the events that are most relevant to you.<br>' +
          '2. Focus on fewer events.<br><br>' +
          'You don’t have to be an expert on every event or market. You only have to be able to evaluate events ' +
          'within your WatchList. The amount of events is not very important, focus however, is vital.',
      },
      'release-magnitude': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Smart plan',
          plan: 'Smart',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Magnitude of Surprise',
        html:
          'No Longer guess the surprise factor!<br>' +
          '<br><b>Magnitude of Surprise tells you really HOW ' +
          'surprising this news event is.<br>',
      },
      'release-report': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Smart plan',
          plan: 'Smart',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Macro-News Report',
        html:
          'Read the report with one click. No need to browse all over the internet to find the report for that ' +
          'event.<br><br>' +
          'Benefits of Macro news plan:<br>' +
          '- Gain clearer insight into what the event is about.<br>' +
          '- Get real time analysis for any events.<br>' +
          '- Make informed decisions.<br>',
      },
      'perspective-insights': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Smart plan',
          plan: 'Smart',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Perspective Insights',
        html:
          'Perspective Insight highlights the big picture!<br><br><b>It allows you to see the big picture of how ' +
          'this news fits in its history such as the highest release in 7 years',
      },
      'custom-charts': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Starter plan',
          plan: 'Smart',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Custom Charts',
        html:
          'Select the charts you want to see.<br><br>' +
          'Integration Benefits:<br>' +
          '- Connected to your watch list<br>' +
          '- 1-click to change chart symbols<br>' +
          '- Auto-save favorite charts',
      },
      backtester: {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Mastermind' : 'Pro plan',
          plan: 'Pro',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock BackTester',
        html:
          'BackTester allows you to do extensive research and understand deeply how the market works.<br><br>' +
          '<b>1. You can see the historical effect of this exact event or change in price.<br><br>' +
          '<b>2. You can create simulations to see possible effects before the event release',
      },
      community: {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Smart plan',
          plan: 'Pro',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Pro Community',
        html:
          'Join a community of professional traders who collaborate and get valuable insights<br><br>' +
          'Top benefits:<br>' +
          '1. Chat - Discuss with other professional traders on topics you are interested in.<br>' +
          '2. Livestream - Get live analysis from Nick, a very successful and experienced pro trader.<br>' +
          '3. Collaborate with other pro traders.<br><br>' +
          'Trading is a competitive game, empower your edge from the community.<br>' +
          'One key trade or insight from this community could pay for the annual subscription alone.',
      },
      'trading-platform': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Starter plan',
          plan: 'Smart',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Broker Integration',
        html:
          "Integrate your broker, and launch it's platform on this screen.<br><br>" +
          '<b>Integration Benefits:</b><br>' +
          '- No need to work with multiple tabs open<br>' +
          '- Keep same broker interface<br>' +
          '- Get analysis and execution at the same screen',
      },
      'instrument-calendar': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Smart plan',
          plan: 'Smart',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Unlock Instrument Calendar',
        html:
          'This is a calendar showing only economic events that are relevant to the selected instrument.<br><br>' +
          '<b>Top Benefits:</b><br>' +
          '- Get back-tested Trade Ideas only to the instruments which you are interested in.<br>' +
          '- Focus on the events that generate opportunities for the selected instrument.<br>' +
          '- Be ready before the next actionable release.',
      },
      'broker-integration': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Smart plan',
          plan: 'Smart',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Connect your broker',
        html:
          'Trade from ' +
          (gIsBigBrainBank ? 'TheBrain' : 'BetterTrader') +
          ' interface using your broker real or demo account without the constant switch of application.<br><br>' +
          '<b>Top Benefits:</b><br>' +
          '- Increase your odds for success.<br>' +
          '- Everything in one screen.<br>' +
          '- Save time and react faster.',
      },
      levels: {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Smart plan',
          plan: 'Smart',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Auto-Generated Support and Resistance Levels',
        html:
          (gIsBigBrainBank ? 'TheBrain' : 'BetterTrader') +
          ' automates this manual work with Dynamic Levels to automatically draw support and resistance for any instrument.<br><br>' +
          '<b>Top Benefits:</b><br>' +
          '- Multiple timeframe analysis.<br>' +
          '- Dynamic calculation (every 6 hours).<br>' +
          '- Real-time voice notifications.',
      },
      'risk-monitor': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Mastermind' : 'Pro plan',
          plan: 'Pro',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Risk-On/Risk-Off Real-time Monitor',
        html:
          'Risk-On/Risk-Off tools help you measure the mood shifts in the markets and evaluating the money flow between asset classes in real-time.<br><br>' +
          '<b>Top Benefits:</b><br>' +
          '- Gauge the overall sentiment of the market.<br>' +
          '- Emerging divergence.<br>' +
          '- Risk-On Risk-Off alerts.<br>' +
          '- Find niche opportunities.',
      },
      'live-news': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Mastermind' : 'Pro plan',
          plan: 'Pro',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Live News',
        html: 'Live TV from around the world',
      },
      'market-characteristics': {
        feature: {
          type: 'enable',
          value: gIsBigBrainBank ? 'Genius' : 'Smart plan',
          plan: 'Smart',
        },
        tag: {
          dynx_itemid: 'subscription',
          dynx_pagetype: 'offerdetail',
          dynx_totalvalue: 0,
        },
        title: 'Market Characteristics',
        html: 'Get In-depth Market Statistics',
      },
    };

    /**
     *
     * @type {Record<string, Function | string>}
     */
    var gFeatures = {
      approval: function () {
        return !!(gSettings && gSettings.hasApproval);
      },
      personalization: hasPersonalization,
      purchases: hasPurchases,
      backtesting: hasBacktesting,
      'trade-ideas': hasTradeIdeas,
      'premium-notifications': hasPremiumNotifications,
      'custom-charts': hasCustomCharts,
      'voice-assistant': hasVoiceAssistant,
      'release-magnitude': hasReleaseMagnitude,
      'release-insights': hasPerspectiveInsights,
      'weekly-mails': hasWeeklyMails,
      community: hasCommunity,
      'broker-integration': hasBrokerIntegration,
      'instrument-calendar': hasInstrumentCalendar,
      'twitter-scanner': function () {
        return gSettings && gSettings.numCustomTwitter > 0;
      },
      'tweet-reading': 'hasTweetReading',
      'trading-platform': hasTradingPlatform,
      'risk-monitor': hasRiskMonitor,
      riskMonitor: hasRiskMonitor,
      levels: function () {
        return !!(gSettings && gSettings.hasLevels);
      },
      trading: function () {
        return !!(gSettings && gSettings.hasTrading);
      },

      weeklyMails: hasWeeklyMails,
      voiceAssistant: hasVoiceAssistant,
      customCharts: hasCustomCharts,
      releaseMagnitude: hasReleaseMagnitude,
      newsDrivenTradeIdeas: hasNewsDrivenTradeIdeas,
      potentialNewsDrivenTradeIdeas: hasPotentialNewsDrivenTradeIdeas,
      priceDrivenTradeIdeas: hasPriceDrivenTradeIdeas,
      perspectiveInsights: hasPerspectiveInsights,
      'live-news': function () {
        return !!(gSettings && gSettings.hasLiveNews);
      },
      'market-characteristics': function () {
        return !!(gSettings && gSettings.hasMarketCharacteristics);
      },
    };

    /**
     *
     * @type {Record<string, string>}
     */
    var gCapacities = {
      'market-sense': 'numMarketSense',
      'market-wakeup': 'numMarketWakeup',
      watchlist: 'numWatchedSymbols',
      'followed-events': 'numFollowEvents',
      'custom-twitter': 'numCustomTwitter',
      'total-twitter': 'numTotalTwitter',
    };

    _initializeRestrictions();

    $rootScope.$on('subscription:loaded', _onSubscriptionUpdate);

    return {
      hasFeature: hasFeature,
      getRestrictions: getRestrictions,
      hasPlugin: hasPlugin,
      getCapacity: getCapacity,
      // hasPersonalization: hasPersonalization,
      // hasBacktesting: hasBacktesting,
      // hasTradeIdeas: hasTradeIdeas,
      // hasNewsDrivenTradeIdeas: hasNewsDrivenTradeIdeas,
      // hasPotentialNewsDrivenTradeIdeas: hasPotentialNewsDrivenTradeIdeas,
      // hasPriceDrivenTradeIdeas: hasPriceDrivenTradeIdeas,
      // hasCustomCharts: hasCustomCharts,
      // hasVoiceAssistant: hasVoiceAssistant,
      // hasReleaseMagnitude: hasReleaseMagnitude,
      // hasPerspectiveInsights: hasPerspectiveInsights,
      // hasWeeklyMails: hasWeeklyMails,
      // hasRiskMonitor: hasRiskMonitor,
      // getMarketSenseCapacity: getMarketSenseCapacity,
      // getMarketWakeupCapacity: getMarketWakeupCapacity,
      // getWatchlistCapacity: getWatchlistCapacity,
      // getFollowEventsCapacity: getFollowEventsCapacity,
      // getCustomTwitterChannelsCapacity: getCustomTwitterChannelsCapacity,
      // getTotalTwitterChannelsCapacity: getTotalTwitterChannelsCapacity,
      showUpgradePopup: showUpgradePopup,
      // showMobileWebBlocker: showMobileWebBlocker,
      showSelectPlanBlocker: showSelectPlanBlocker,
      // showFirstTrialFinishBlocker: showFirstTrialFinishBlocker
    };

    /**
     *
     * @return {Partial<ecapp.btSubscriptionSettingsObject>}
     */
    function getRestrictions() {
      return gSettings || {};
    }

    /**
     * This function checks if user has access to the feature.
     *
     * @alias ecapp.btRestrictionService#hasFeature
     * @param {string} name - feature name
     * @return {boolean}
     */
    function hasFeature(name) {
      return !!getSettings(gFeatures, name, false);
    }

    /**
     * This function returns user capacity for the feature.
     *
     * @alias ecapp.btRestrictionService#getCapacity
     * @param {string} name - feature name
     * @return {number}
     */
    function getCapacity(name) {
      return getSettings(gCapacities, name, 0);
    }

    /**
     * This function get value from dictionary.
     *
     * @private
     * @param {object} dict - dictionary object
     * @param {string} key - key name
     * @param {boolean|number} defaultValue - default value
     * @return {boolean|number}
     */
    function getSettings(dict, key, defaultValue) {
      if (typeof dict[key] === 'function') return dict[key]();
      if (typeof dict[key] === 'string') return gSettings ? gSettings[dict[key]] : defaultValue;
      return defaultValue;
    }

    /**
     * This function check if user has access to the plugin.
     *
     * @alias ecapp.btRestrictionService#hasPlugin
     * @param {string} name - plugin name
     * @return {boolean}
     */
    function hasPlugin(name) {
      return !!(gSettings && gSettings.plugins.indexOf(name) !== -1);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasPersonalization
     * @return {boolean}
     */
    function hasPersonalization() {
      return !!(gSettings && gSettings.hasPersonalization);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasPurchases
     * @return {boolean}
     */
    function hasPurchases() {
      return !!(gSettings && gSettings.hasPurchases);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasBacktesting
     * @return {boolean}
     */
    function hasBacktesting() {
      return !!(gSettings && gSettings.hasBacktesting);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasCommunity
     * @return {boolean}
     */
    function hasCommunity() {
      return !!(gSettings && gSettings.hasCommunity);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasTradeIdeas
     * @return {boolean}
     */
    function hasTradeIdeas() {
      return !!(gSettings && gSettings.hasTradeIdeas);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasRiskMonitor
     * @return {boolean}
     */
    function hasRiskMonitor() {
      return !!(gSettings && gSettings.hasRiskMonitor);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasNewsDrivenTradeIdeas
     * @return {boolean}
     */
    function hasNewsDrivenTradeIdeas() {
      return !!(gSettings && gSettings.hasTradeIdeas);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasPotentialNewsDrivenTradeIdeas
     * @return {boolean}
     */
    function hasPotentialNewsDrivenTradeIdeas() {
      return !!(gSettings && gSettings.hasTradeIdeas);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasPriceDrivenTradeIdeas
     * @return {boolean}
     */
    function hasPriceDrivenTradeIdeas() {
      return !!(gSettings && gSettings.hasTradeIdeas);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasPremiumNotifications
     * @return {boolean}
     */
    function hasPremiumNotifications() {
      return !!(gSettings && gSettings.hasPremiumNotifications);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasCustomCharts
     * @return {boolean}
     */
    function hasCustomCharts() {
      return !!(gSettings && gSettings.hasCustomCharts);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasVoiceAssistant
     * @return {boolean}
     */
    function hasVoiceAssistant() {
      return !!(gSettings && gSettings.hasVoiceAssistant);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasReleaseMagnitude
     * @return {boolean}
     */
    function hasReleaseMagnitude() {
      return !!(gSettings && gSettings.hasReleaseMagnitude);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasPerspectiveInsights
     * @return {boolean}
     */
    function hasPerspectiveInsights() {
      return !!(gSettings && gSettings.hasPerspectiveInsights);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasWeeklyMails
     * @return {boolean}
     */
    function hasWeeklyMails() {
      return !!(gSettings && gSettings.hasWeeklyMails);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasTradingPlatform
     * @return {boolean}
     */
    function hasTradingPlatform() {
      return !!(gSettings && gSettings.hasTradingPlatform);
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasBrokerIntegration
     * @return {boolean}
     */
    function hasBrokerIntegration() {
      return hasTradeIdeas() && hasReleaseMagnitude();
    }

    /**
     *
     * @alias ecapp.btRestrictionService#hasInstrumentCalendar
     * @return {boolean}
     */
    function hasInstrumentCalendar() {
      return hasTradeIdeas() && hasReleaseMagnitude();
    }

    /**
     *
     * @alias ecapp.btRestrictionService#getMarketSenseCapacity
     * @return {number}
     */
    function getMarketSenseCapacity() {
      if (gSettings && gSettings.numMarketSense !== undefined) {
        return gSettings.numMarketSense;
      } else {
        return 0;
      }
    }

    /**
     *
     * @alias ecapp.btRestrictionService#getMarketWakeupCapacity
     * @return {number}
     */
    function getMarketWakeupCapacity() {
      if (gSettings && gSettings.numMarketWakeup !== undefined) {
        return gSettings.numMarketWakeup;
      } else {
        return 0;
      }
    }

    /**
     *
     * @alias ecapp.btRestrictionService#getWatchlistCapacity
     * @return {number}
     */
    function getWatchlistCapacity() {
      if (gSettings && gSettings.numWatchedSymbols !== undefined) {
        return gSettings.numWatchedSymbols;
      } else {
        return 0;
      }
    }

    /**
     *
     * @alias ecapp.btRestrictionService#getFollowEventsCapacity
     * @return {number}
     */
    function getFollowEventsCapacity() {
      if (gSettings && gSettings.numFollowEvents !== undefined) {
        return gSettings.numFollowEvents;
      } else {
        return 0;
      }
    }

    /**
     *
     * @alias ecapp.btRestrictionService#getCustomTwitterChannelsCapacity
     * @return {number}
     */
    function getCustomTwitterChannelsCapacity() {
      if (gSettings && gSettings.numCustomTwitter !== undefined) {
        return gSettings.numCustomTwitter;
      } else {
        return 0;
      }
    }

    /**
     *
     * @alias ecapp.btRestrictionService#getTotalTwitterChannelsCapacity
     * @return {number}
     */
    function getTotalTwitterChannelsCapacity() {
      if (gSettings && gSettings.numTotalTwitter !== undefined) {
        return gSettings.numTotalTwitter;
      } else {
        return 0;
      }
    }

    /**
     * This function show upgrade popup.
     *
     * @example
     *  btRestrictionService.showUpgradePopup('follow-events')
     *    .then(function (status) {
     *      if (status === 'updated') console.log('was updated');
     *      else console.log('was not updated');
     *    });
     * @alias ecapp.btRestrictionService#showUpgradePopup
     * @param {string} type - type of upgrade popup
     * @return {angular.IPromise<String>} - return status 'updated' or 'closed'
     */
    function showUpgradePopup(type) {
      console.log('btRestrictionService: open popup', type);

      if (gFeatureTypes[type] === undefined) {
        console.error('Unknown pop-up type: ' + type);
        return $q.reject(new Error('Unknown pop-up type: ' + type));
      }

      $analytics.eventTrack('show-upgrade', { category: 'purchases', label: type });
      btTagService.intendUpgrade(gFeatureTypes[type].tag);

      if (gScopeCache[type]) {
        console.log('btRestrictionService: use old scope');

        var $scope = gScopeCache[type];

        if ($scope.modal === undefined) {
          console.log('btRestrictionService: Upgrade popup in process');
          return $q.reject('btRestrictionService: Upgrade popup in process');
        } else if ($scope.modal && $scope.modal.isShown()) {
          console.log('btRestrictionService: Upgrade popup is already open');
          return $q.reject('btRestrictionService: Upgrade popup is already open');
        } else {
          $scope.deferred = $q.defer();

          if (!$scope.modal.isShown()) {
            $scope.modal.show();
            $scope.selectSuggestedPlan($scope);
          }

          console.log('btRestrictionService: Show upgrade popup again');
          return $scope.deferred.promise;
        }
      } else {
        console.log('btRestrictionService: initialize scope');
        gScopeCache[type] = $rootScope.$new(true);
      }

      console.log('btRestrictionService: show popup');

      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Loading...</p>' });

      return btSubscriptionService
        .getPlans()
        .then(btSubscriptionService.prepareApplePlans)
        .then(btSubscriptionService.filterInAppPlans)
        .then(function (plans) {
          return btSubscriptionService.filterUpgradePlans(
            btSubscriptionService.getCombinedSubscription(),
            btSubscriptionService.status.sub,
            plans
          );
        })
        .then(btSubscriptionService.sortPlans)
        .then(function (plans) {
          return openPaymentDialog(gScopeCache[type], type, plans);
        })
        .finally(function () {
          $ionicLoading.hide();
        });
    }

    /**
     *
     * @param {ecapp.ICustomScope} $scope
     * @param {string} type
     * @param {btPlanObject[]} plans
     * @return {angular.IPromise<string>}
     */
    function openPaymentDialog($scope, type, plans) {
      $scope.deferred = $q.defer();

      $scope.isProcessing = false;

      // slider options
      $scope.options = { loop: true };

      // feature data
      $scope.data = gFeatureTypes[type];

      $scope.payment = {
        interval: 'year',
        isAnnually: true,
      };

      // list of planes
      $scope.plans = plans;

      plans.forEach(function (plan) {
        if (plan.interval === 'year' && !plan.opposite) {
          plan.opposite = plans.filter(function (item) {
            return item.name === plan.name && item.interval === 'month';
          })[0];
        }
      });

      var subscription = btSubscriptionService.getCombinedSubscription();

      if (btSettingsService.getDomain() === 'bigbrainbank') {
        $scope.isRegular = true;
        $scope.isOptimus = false;
        $scope.isBigBrainBank = true;
      } else if (subscription.id === 'optimus-demo') {
        $scope.isRegular = false;
        $scope.isOptimus = true;
        $scope.isBigBrainBank = false;
      } else {
        $scope.isRegular = true;
        $scope.isOptimus = false;
        $scope.isBigBrainBank = false;
      }

      $scope.optimusAccountHref = btSettingsService.getAssets('open-account-href');
      $scope.optimusLearnMoreHref = btSettingsService.getAssets('learn-more-href');

      $scope.hasMonthlyPlans = checkPlansInterval(plans, 'month');
      $scope.hasAnnualPlans = checkPlansInterval(plans, 'year');
      $scope.hasPlans = $scope.hasMonthlyPlans || $scope.hasAnnualPlans;

      $scope.hasIntervalSwitch = $scope.hasAnnualPlans && $scope.hasMonthlyPlans;
      if (!$scope.hasIntervalSwitch) {
        if ($scope.hasMonthlyPlans) {
          $scope.payment.interval = 'month';
          $scope.payment.isAnnually = false;
        }

        if ($scope.hasAnnualPlans) {
          $scope.payment.interval = 'year';
          $scope.payment.isAnnually = true;
        }
      }

      selectSuggestedPlan($scope);

      $scope.selectSuggestedPlan = selectSuggestedPlan;
      $scope.buySubscriptionWrapper = buySubscriptionWrapper.bind(null, $scope);
      $scope.$watch('payment.isAnnually', onPaymentIntervalChange.bind(null, $scope));

      $scope.$on('$ionicSlides.sliderInitialized', onSliderInitialized.bind(null, $scope));

      showPopUpDialog($scope, type, 'modals/bt-upgrade-modal.html');

      return $scope.deferred.promise;
    }

    /**
     *
     * @param {btPlanObject[]} plans
     * @param {string} interval
     * @return {boolean}
     */
    function checkPlansInterval(plans, interval) {
      return (
        plans.filter(function (plan) {
          return plan.interval === interval && (plan.productId || !window.isIOS);
        }).length > 0
      );
    }

    /**
     *
     * @param {ecapp.ICustomScope} $scope
     */
    function selectSuggestedPlan($scope) {
      if ($scope && $scope.plans) {
        // index of selected plan
        $scope.selected = 1;
        var i = 0;
        $scope.plans.forEach(function (plan) {
          if (plan.interval === $scope.payment.interval) {
            if (plan.name === $scope.data.feature.plan) {
              $scope.selected = i + 1;
            }
            i++;
          }
        });

        if ($scope.slider && typeof $scope.slider.slideTo === 'function') {
          $timeout(function () {
            $scope.slider.slideTo($scope.selected);
          }, 500);
        }
      }
    }

    /**
     *
     * @param {ecapp.ICustomScope} $scope
     * @param {boolean} newValue
     * @param {boolean} oldValue
     */
    function onPaymentIntervalChange($scope, newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }

      if (newValue) {
        $scope.payment.interval = 'year';
      } else {
        $scope.payment.interval = 'month';
      }
    }

    /**
     *
     * @param {ecapp.ICustomScope} $scope
     * @param {*} event
     * @param {*} data
     */
    function onSliderInitialized($scope, event, data) {
      void event;
      // data.slider is the instance of Swiper
      /**
       * @type {{slideTo: Function}}
       */
      $scope.slider = data.slider;
      $timeout(function () {
        $scope.slider.slideTo($scope.selected);
      }, 500);
    }

    /**
     *
     * @param {ecapp.ICustomScope} $scope
     * @param {*} plan
     * @param {function} buySubscription
     */
    function buySubscriptionWrapper($scope, plan, buySubscription) {
      $scope.isProcessing = true;
      if (typeof buySubscription === 'function') {
        var promise = buySubscription(plan, '', $scope.modal);
        if (promise && typeof promise.then === 'function') {
          promise
            .then(function (status) {
              if (status === 'success') {
                btToastrService.success('Congratulations. You have subscription!', 'Subscription', {
                  type: 'settings',
                });
                $scope.deferred.resolve('updated');
                if ($scope.modal.isShown()) $scope.modal.hide();

                $rootScope.scheduleAppRestart();
              }
            })
            .catch(function (reason) {
              btToastrService.error(reason.message, 'Subscription', { type: 'settings' });
              console.error('Upgrade popup error:', reason);
              if (!$scope.modal.isShown()) {
                $scope.modal.show();
              }
            })
            .finally(function () {
              $scope.isProcessing = false;
            });
        }
      }
    }

    /**
     * This function create a new modal view.
     * Scope variable `modal` will be used to store modal view object.
     * Scope variable `isProcessing` could be used to control closing process.
     * Scope variable `deferred` could be used to detect view closing.
     *
     * @private
     * @param {string} template - modal template
     * @param {string} view - virtual pageview
     * @param {{scope: *}} params - modal parameters
     * @return {*} - modal view object
     */
    function createModal(template, view, params) {
      params.scope.modal = $ionicModal.fromTemplate($templateCache.get(template), params);

      $analytics.pageTrack(view);
      params.scope.modal.show();

      /**
       *
       * @return {angular.IPromise}
       */
      params.scope.showModal = function showModal() {
        $analytics.pageTrack(view);
        return params.scope.modal.show();
      };

      /**
       *
       * @return {angular.IPromise}
       */
      params.scope.closeModal = function closeModal() {
        var url = $location.url();
        $analytics.pageTrack(url, $location);
        return params.scope.modal.hide();
      };

      params.scope.$on('modal.hidden', function onHide() {
        if (!params.scope.isProcessing && params.scope.deferred) {
          params.scope.deferred.resolve('closed');
        }
      });

      params.scope.$on('modal.shown', function onShow() {
        $ionicLoading.hide();
      });

      params.scope.$on('$destroy', function onDestroy() {
        // console.log('TEST: Destroying modal scope');
        if (params.scope.modal) params.scope.modal.remove();
      });

      return params.scope.modal;
    }

    /**
     * This function shows pop-up dialog that can be closed by user.
     *
     * @private
     * @see createModal
     * @param {*} scope - angular scope object
     * @param {string} type - type of upgrade dialog
     * @param {string} template - relative path to template
     * @return {*} - modal view object
     */
    function showPopUpDialog(scope, type, template) {
      return createModal(template, 'modal/upgrade/' + type, { scope: scope, animation: 'slide-in-up' });
    }

    /**
     * This function shows pop-up dialog that can not be closed by user.
     *
     * @private
     * @see createModal
     * @param {*} scope - angular scope object
     * @param {string} type - type of blocker
     * @param {string} template - relative path to template
     * @return {*} - modal view object
     */
    function showPopUpBlocker(scope, type, template) {
      return createModal(template, 'modal/blocker/' + type, {
        scope: scope,
        animation: 'slide-in-up',
        backdropClickToClose: false,
        hardwareBackButtonClose: false,
      });
    }

    /**
     *
     * @private
     */
    function _initializeRestrictions() {
      _updateRestrictions(btSubscriptionService.getCombinedSubscription());
    }

    /**
     *
     * @param {*} event
     * @param {btSubscriptionObject} subscription
     * @private
     */
    function _onSubscriptionUpdate(event, subscription) {
      void event;
      _clearScopes();
      _updateRestrictions(subscription);
    }

    /**
     *
     * @param {btSubscriptionObject} subscription
     * @private
     */
    function _updateRestrictions(subscription) {
      if (subscription) {
        gSettings = subscription.settings;
      }
    }

    /**
     *
     * @private
     */
    function _clearScopes() {
      for (var name in gScopeCache) {
        if (gScopeCache.hasOwnProperty(name)) {
          gScopeCache[name].$destroy();
          delete gScopeCache[name];
        }
      }
    }

    // /**
    //  * This function shows pop-up dialog that can not be closed by user.
    //  *
    //  * @alias ecapp.btRestrictionService#showMobileWebBlocker
    //  * @see createModal
    //  * @param {*} scope - angular scope object
    //  * @return {*} - modal view object
    //  */
    // function showMobileWebBlocker(scope) {
    //   var result = {};
    //
    //   var template = 'modals/bt-blocker-mobile-web.html';
    //
    //   result.modal = showPopUpBlocker(scope, template);
    //
    //   return result;
    // }

    /**
     * This function shows pop-up dialog that can not be closed by user.
     *
     * @alias ecapp.btRestrictionService#showSelectPlanBlocker
     * @see createModal
     * @param {*?} scope - angular scope object
     * @return {{modal: *}} - modal view object
     */
    function showSelectPlanBlocker(scope) {
      var wasCreated = false;
      var wasRemoved = false;

      if (!scope) {
        // Create new isolated scope if it was not initialized. It should be destroyed later.
        scope = $rootScope.$new(true);
        wasCreated = true;
      }

      scope.wasClosed = localStorage.getItem('btBlockerWasClosed');
      scope.wasClosed = scope.wasClosed ? Date.now() < parseInt(scope.wasClosed) + 3600000 : false;

      var result = {
        remove: function () {
          wasRemoved = true;
          if (wasCreated) scope.$destroy();
        },
      };

      var template = 'modals/bt-blocker-select-plan.html';

      scope.selectedPlan = 'Smart';

      scope.$on('$destroy', function onDestroy() {
        // console.log('TEST: Destroying scope of "Select Plan" blocker');
        $timeout.cancel(scope.repeater);
      });

      scope.openSpecificNeeds = function () {
        openIntercomWindow('I have a specific need.');
      };

      scope.openAskQuestion = function () {
        openIntercomWindow('I have a question about Premium Plans.');
      };

      scope.openMoreTime = function () {
        openIntercomWindow('I need more time to discover the application.');
      };

      scope.openPersonalOffer = function () {
        openIntercomWindow('I am looking for personal offer.');
      };

      scope.extendTrial = function () {
        var confirmPopup = $ionicPopup.confirm({
          title: 'Would you like to use free limited version?',
          okText: 'Downgrade',
          cssClass: '',
          template:
            '<div>' +
            '<b>You will lose access to next features:</b><br>' +
            '1. Charts customization.<br>' +
            '2. Voice Assistant.<br>' +
            '3. Macro News in real time.<br>' +
            '4. Trading platform integration.<br>' +
            '5. Weekly mails.<br>' +
            '6. Support & resistance levels.<br>' +
            '7. Risk-On/Risk-Off realtime monitor.<br>' +
            '<br>' +
            '<b>Next capacities will be reduced:</b><br>' +
            '1. Your watchlist will be limited to 4 instruments.<br>' +
            '2. You will have ability to follow just 10 economic events.' +
            '</div>',
        });

        return confirmPopup.then(function (confirmed) {
          if (confirmed) {
            btSubscriptionService.extendTrial().then(function (value) {
              if (value) {
                $rootScope.scheduleAppRestart();
              }
            });
          } else {
            console.log('Terminated by user.');
          }
        });
      };

      scope.renewTrial = function () {};

      scope.giveImmediateAccess = function () {
        scope.closeModal();
        scope.wasClosed = true;
        localStorage.setItem('btBlockerWasClosed', Date.now().toString());

        scope.repeater = $timeout(function () {
          scope.showModal();
        }, 60000);
      };

      scope.payment = btSubscriptionService.getPaymentSettings();
      scope.$watch('payment.isAnnual', btSubscriptionService.togglePaymentInterval);

      scope.buySubscription = function (plan) {
        scope
          .closeModal()
          .then(function () {
            return btSubscriptionService.buySubscription(plan);
          })
          .then(function (value) {
            if (value !== 'success') {
              scope.showModal();
            } else {
              btToastrService.success(
                'Congratulations. You have a subscription! Application will be restarted in a moment',
                'Subscription',
                { type: 'settings' }
              );
              $rootScope.scheduleAppRestart();
            }
          })
          .catch(function (reason) {
            console.log(reason);
            btToastrService.error(reason.message, 'Subscription', { type: 'settings' });
            scope.showModal();
          });
      };

      scope.plans = [];

      // console.log('TEST: Loading blocker settings');
      btShareScopeService
        .wait()
        .then(btSubscriptionService.getPurchases)
        .then(function (purchases) {
          // console.log('TEST: Purchases was loaded');
          var plans = purchases.subscriptions.sort(function (a, b) {
            return parseInt(a.metadata.level) - parseInt(b.metadata.level);
          });

          var promises = plans.map(function (plan) {
            // !!! Quick fix it should be moved to server (search for more places)
            plan.available = !(plan.interval === 'month' && plan.name.indexOf('Starter') !== -1);

            if (plan.interval === 'year') {
              return btSubscriptionService
                .getSpecificPurchase('subscriptions', plan.name, 30)
                .then(function (opposite) {
                  plan.opposite = opposite;
                  return plan;
                });
            } else {
              return $q.resolve(plan);
            }
          });

          return $q.all(promises);
        })
        .then(function (plans) {
          scope.plans = plans;
        })
        .then(btSubscriptionService.getOffers)
        .then(function (offers) {
          // console.log('TEST: Offers was loaded');

          scope.trialExtension = offers.filter(function (offer) {
            return offer.id === 'trial-extension';
          })[0];
          scope.hasTrialExtension = !!scope.trialExtension;

          scope.trialRenewal = offers.filter(function (offer) {
            return offer.id === 'trial-renewal';
          })[0];
          scope.hasTrialRenewal = !!scope.trialRenewal;

          scope.personalDiscount = offers.filter(function (offer) {
            return offer.id === 'personal-discount';
          })[0];
          scope.hasPersonalDiscount = !!scope.personalDiscount;
        })
        .then(function () {
          scope.userName = btShareScopeService.accountInfo.firstName;
          scope.userEmail = btShareScopeService.accountInfo.email;

          // console.log('TEST: Showing blocker');
          var type = getBlockerType(scope.hasTrialExtension, btShareScopeService.accountInfo);
          if (!wasRemoved) result.modal = showPopUpBlocker(scope, type, template);
        });

      return result;
    }

    /**
     *
     * @param {boolean} hasTrialExtension
     * @param {object} userAccount
     * @return {string}
     */
    function getBlockerType(hasTrialExtension, userAccount) {
      if (hasTrialExtension) return 'pending-free';
      else if (userAccount.variant === 'A') return 'blocked-free';
      else return 'blocked-trial';
    }

    // /**
    //  * This function shows pop-up dialog that can not be closed by user.
    //  *
    //  * @alias ecapp.btRestrictionService#showFirstTrialFinishBlocker
    //  * @see createModal
    //  * @param {*} scope - angular scope object
    //  * @return {*} - modal view object
    //  */
    // function showFirstTrialFinishBlocker(scope) {
    //   scope.selectedPlan = 'Smart';
    //   return showPopUpBlocker(scope, 'modals/bt-blocker-first-trial-finish.html');
    // }

    /**
     *
     * @param {string} text
     */
    function openIntercomWindow(text) {
      if (window.intercom) {
        if (text) {
          window.intercom.displayMessageComposerWithInitialMessage(text);
        } else {
          window.intercom.displayMessageComposer();
        }
      } else if (window.Intercom) {
        if (text) {
          window.Intercom('showNewMessage', text);
        } else {
          window.Intercom('show');
        }
      }
    }
  }
})();
