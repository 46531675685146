(function btSubscriptionStatusClosure() {
  'use strict';

  /**
   * ???
   * @ngdoc directive
   * @name btSubscriptionStatus
   * @memberOf ecapp
   */

  // noinspection JSValidateTypes
  angular
    .module('ecapp')
    .directive('btSubscriptionStatus', btSubscriptionStatus)
    .controller('btSubscriptionStatusController', btSubscriptionStatusController);

  btSubscriptionStatus.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btSubscriptionStatus($templateCache) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        // email: '=',
        // token: '=',
        // type: '='
      },
      template: $templateCache.get('directives/common/bt-subscription-status.html'),
      controller: 'btSubscriptionStatusController',
    };
  }

  btSubscriptionStatusController.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    '$window',
    '$state',
    '$filter',
    '$ionicLoading',
    'btDateService',
    'btSubscriptionService',
    '$anchorScroll',
    'btSettingsService',
    'btShareScopeService',
    'btRestrictionService',
    'btToastrService',
    'btMarketing',
  ];

  /**
   *
   * @param {SubscriptionStatusScope} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.ITimeoutService} $timeout
   * @param {angular.IWindowService} $window - window object service
   * @param {angular.ui.IStateService} $state
   * @param {angular.IFilterService} $filter
   * @param {ionic.ILoadingService} $ionicLoading - ionic loading service
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   * @param {angular.IAnchorScrollService} $anchorScroll
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IMarketing} btMarketing
   */
  function btSubscriptionStatusController(
    $scope,
    $rootScope,
    $timeout,
    $window,
    $state,
    $filter,
    $ionicLoading,
    btDateService,
    btSubscriptionService,
    $anchorScroll,
    btSettingsService,
    btShareScopeService,
    btRestrictionService,
    btToastrService,
    btMarketing
  ) {
    var gSubscription;
    var gUser;

    // var gDebug = false;

    $scope.hasPurchases = btRestrictionService.hasFeature('purchases') && !$rootScope.isLifetime;
    $scope.isOptimus = btSettingsService.domain === 'optimusfutures';
    $scope.isBigBrainBank = btSettingsService.domain === 'bigbrainbank';
    $scope.isBetterTrader = btSettingsService.domain === 'bettertrader';

    $scope.managedByApple = false;
    $scope.upgradeButtonText = 'Select plan';

    $scope.sub = 0; //0 - FREE, 1 - TRIAL, 2 - SUBSCRIPTION, 3 - TESTING
    $scope.endText = 'Ends';
    $scope.recurring = false;
    $scope.plans = null;
    $scope.selected = null;

    // States
    $scope.loadingPayment = false;
    $scope.loadingUserData = true;
    $scope.loadingAccountData = false;
    $scope.unsubscribing = false;
    $scope.showPromo = false;
    $scope.applyDiscount = false;
    $scope.payment = { isAnnually: true, interval: 'year' };
    $scope.status = btSubscriptionService.status;

    $scope.optimusAccountHref = btSettingsService.getAssets('open-account-href');
    $scope.optimusLearnMoreHref = btSettingsService.getAssets('learn-more-href');

    $scope.coupon = coupon;
    $scope.unsubscribe = unsubscribe;
    $scope.manageBilling = manageBilling;
    $scope.selectPlan = selectPlan;
    $scope.openPromo = openPromo;
    $scope.openPrivacy = openPrivacy;
    $scope.openTerms = openTerms;
    $scope.buySubscriptionWrapper = buySubscriptionWrapper;
    $scope.restorePurchases = restorePurchases;
    $scope.openIntercom = openIntercom;
    $scope.buyLifetimePro = buyLifetimePro;

    $scope.$watch('payment.isAnnually', onPaymentIntervalChange);

    activate();

    /**
     * This function actives controller.
     */
    function activate() {
      $scope.type = btSubscriptionService.status.provider;

      gSubscription = btSubscriptionService.getCombinedSubscription();
      $scope.sub_tax = gSubscription.tax;

      $scope.managedByApple =
        gSubscription.origin === 'apple' && gSubscription.status !== 'unpaid' && $scope.type === 'stripe';

      // update subscription on init
      btSubscriptionService
        .updateSubscription()
        .then(function (subscription) {
          return btShareScopeService.getAccountSettingsPromise().then(function (user) {
            return { subscription: subscription, user: user };
          });
        })
        .then(function (data) {
          gSubscription = data.subscription;
          $scope.sub_tax = gSubscription.tax;
          gUser = data.user;

          $scope.loadingUserData = false;

          _prepareSubscriptionData();
        })
        .catch(function (reason) {
          btToastrService.error(reason.message, 'Subscription', { type: 'settings' });
        });
    }

    /**
     *
     */
    function buyLifetimePro() {
      btSubscriptionService
        .buyLifetimePro()
        .then(function (result) {
          if (result.error) {
            btToastrService.error(result.error.message, 'Subscription', { type: 'settings' });
          }
        })
        .catch(function (err) {
          btToastrService.error(err.message, 'Subscription', { type: 'settings' });
        });
    }

    /**
     * Checks for plans with specific interval.
     *
     * @param {PlanObject[]} plans - list of subscription plans
     * @param {string} interval - interval identifier
     * @return {boolean} - whether plans with specific interval exist
     */
    function checkPlansInterval(plans, interval) {
      return (
        plans.filter(function (plan) {
          return plan.interval === interval && (plan.productId || !($window.isIOS && $window.isApp));
        }).length > 0
      );
    }

    /**
     * Opens Intercom chat with question.
     */
    function openIntercom() {
      btMarketing.askQuestion('I have a question regarding the pricing:');
    }

    /**
     * Verifies coupon code.
     *
     * @param {string} code - coupon code
     */
    function coupon(code) {
      if (!code || code === '') return;

      $scope.errorPromo = false;
      $scope.loadingCoupon = true;

      btSubscriptionService
        .verifyCoupon(code)
        .then(
          /** @param {StripeCoupon} coupon */ function (coupon) {
            if (coupon) {
              if (!coupon.valid) {
                $scope.errorPromo = true;
                $scope.errorText = 'Code is no longer valid';
                $scope.loadingCoupon = false;
                return;
              }

              if (coupon.redeem_by) {
                $scope.couponDue = btDateService.getFullFormattedDate(coupon.redeem_by * 1000);
              }

              $scope.couponData = coupon;
              $scope.applyDiscount = true;
              $scope.loadingCoupon = false;
            } else {
              $scope.errorPromo = true;
              $scope.errorText = 'Code you have entered does not exist';
              $scope.loadingCoupon = false;
            }
          }
        )
        .catch(function (reason) {
          $scope.errorPromo = true;
          $scope.errorText = reason.message || 'Invalid coupon code';
          $scope.loadingCoupon = false;
        });
    }

    /**
     *
     */
    function manageBilling() {
      btSubscriptionService
        .manageBilling()
        .then(function (result) {
          console.log('Manage billing result: ', result);
          window.location.href = result.data.url;
        })
        .catch(function (err) {
          console.log('Manage billing error: ', err);
        });
    }

    /**
     * Initializes unsubscribing process.
     *
     * @param {?boolean} confirm - flag to start (null), finish (true) or cancel (false) process
     */
    function unsubscribe(confirm) {
      if (confirm === undefined) $scope.unsubscribing = true;
      else if (confirm === false) $scope.unsubscribing = false;
      else {
        $scope.loadingAccountData = true;
        btSubscriptionService
          .cancelSubscription(gSubscription)
          .then(function (result) {
            console.log('Unsubscribe result: ', result);
            $scope.loadingAccountData = false;
            $rootScope.dataChanged = true;
            $state.reload();
          })
          .catch(function (err) {
            console.log('Unsubscribe error: ', err);
            $scope.loadingAccountData = false;
          });
      }
    }

    /**
     * Buys Apple or Stripe subscription.
     *
     * @param {PlanObject} plan - plan object
     * @param {Function} buySubscription - function to buy or upgrade stripe subscription
     */
    function buySubscriptionWrapper(plan, buySubscription) {
      $anchorScroll(plan.id);
      if ($scope.type === 'stripe') {
        buyStripeSubscription(plan, buySubscription);
      } else if ($scope.type === 'apple') {
        buyAppleSubscription(plan);
      } else {
        btToastrService.error('Something went wrong: Bad subscription provider', 'Subscription', { type: 'settings' });
      }
    }

    /**
     * Buys Stripe subscription.
     *
     * @param {PlanObject} plan - plan object
     * @param {Function} buySubscription - function to buy or upgrade stripe subscription
     */
    function buyStripeSubscription(plan, buySubscription) {
      if (typeof buySubscription === 'function') {
        var couponId = $scope.couponData ? $scope.couponData.id : '';

        plan = applyCoupon(plan, $scope.couponData);

        var promise = buySubscription(plan, couponId);
        if (promise && typeof promise.then === 'function') {
          promise.then(onSuccess).catch(onFail);
        }
      }
    }

    /**
     * Recalculates plan price.
     *
     * @param {PlanObject} plan - plan object
     * @param {StripeCoupon} coupon - coupon object
     * @return {PlanObject} modified plan object
     */
    function applyCoupon(plan, coupon) {
      if ($scope.applyDiscount && coupon) {
        if (coupon.percent_off) plan.amount = (plan.amount / 100) * (100 - coupon.percent_off);
        else plan.amount = plan.amount - coupon.amount_off;
      }
      return plan;
    }

    /**
     * Buys Apple subscription.
     *
     * @param {PlanObject} plan - plan object
     */
    function buyAppleSubscription(plan) {
      btSubscriptionService.applePay(plan, '').then(onSuccess).catch(onFail);
    }

    /**
     *
     * @param {string} status - it just be `success`
     */
    function onSuccess(status) {
      if (status === 'success') {
        btToastrService.success('Congratulations. You have subscription!', 'Subscription', { type: 'settings' });
      } else {
        btToastrService.error('Bad status: ' + status, 'Subscription', { type: 'settings' });
      }

      $rootScope.scheduleAppRestart();
    }

    /**
     *
     * @param {Error} reason - rejection reason
     */
    function onFail(reason) {
      console.error(reason);
      btToastrService.error(reason.message, 'Subscription', { type: 'settings' });
    }

    /**
     * Selects subscription plan.
     *
     * @param {PlanObject} plan - plan object
     * @param {Function} buySubscription - function to buy or upgrade stripe subscription
     * @param {Function} selectedCancel - cancel previous selection
     */
    function selectPlan(plan, buySubscription, selectedCancel) {
      if (typeof $scope.selectedCancel === 'function') {
        $scope.selectedCancel();
      }

      $scope.selected = /**@type {PlanObject}*/ plan;
      $scope.selectedBuySubscription = buySubscription;
      $scope.selectedCancel = selectedCancel;
      $scope.upgradeButtonText = 'Upgrade to ' + plan.name;
    }

    /**
     * Opens promo.
     */
    function openPromo() {
      $scope.showPromo = true;
    }

    /**
     * Opens privacy policy.
     */
    function openPrivacy() {
      window.open('https://bettertrader.co/privacy-policy.html', '_system');
    }

    /**
     * Opens term of use.
     */
    function openTerms() {
      window.open('https://bettertrader.co/terms-of-use.html', '_system');
    }

    /**
     * Prepares subscription plans.
     *
     * @private
     * @return {angular.IPromise<any>}
     */
    function _preparePlans() {
      return btSubscriptionService
        .getPlans()
        .then(btSubscriptionService.prepareApplePlans)
        .then(btSubscriptionService.filterInAppPlans)
        .then(function (plans) {
          return btSubscriptionService.filterUpgradePlans(gSubscription, $scope.sub, plans);
        })
        .then(btSubscriptionService.sortPlans)
        .then(modifyPlans)
        .then(sortPlans)
        .then(prepareIntervalInfo)
        .then(function (plans) {
          $scope.plans = plans;
        })
        .catch(function (error) {
          // TODO add handler
          console.log('Plans Error -', JSON.stringify(error));
        });
    }

    /**
     * Prepares interval information.
     *
     * @private
     * @param {PlanObject[]} plans - subscription plans
     * @return {PlanObject[]}
     */
    function prepareIntervalInfo(plans) {
      $scope.hasMonthlyPlans = checkPlansInterval(plans, 'month');
      $scope.hasAnnualPlans = checkPlansInterval(plans, 'year');
      $scope.hasPlans = $scope.hasMonthlyPlans || $scope.hasAnnualPlans;

      $scope.hasIntervalSwitch = $scope.hasAnnualPlans && $scope.hasMonthlyPlans;

      if (!$scope.hasIntervalSwitch) {
        if ($scope.hasAnnualPlans) {
          $scope.payment.interval = 'year';
          $scope.payment.isAnnually = true;
        } else if ($scope.hasMonthlyPlans) {
          $scope.payment.interval = 'month';
          $scope.payment.isAnnually = false;
        }
      }

      return plans;
    }

    /**
     * Modifies subscription plans.
     *
     * @private
     * @param {btPlanObject[]} plans - subscription plans
     * @return {btPlanObject[]}
     */
    function modifyPlans(plans) {
      console.log('btSubscriptionStatusController:', plans);

      plans.forEach(function (plan) {
        if (plan.trial_period_days) {
          plan.hasTrial = true;
          plan.button = 'Start ' + plan.trial_period_days.toString() + ' days free trial';
        } else {
          plan.hasTrial = false;
          plan.button = 'Upgrade';
        }

        // Starter Monthly is unavailable
        // !!! Quick fix it should be moved to server (search for more places)
        plan.available = !(plan.interval === 'month' && plan.name.indexOf('Starter') !== -1);

        if (plan.metadata.regular) plan.regular = parseInt(plan.metadata.regular);
        else plan.regular = 0;

        if (
          plan.interval === $scope.payment.interval &&
          plan.metadata.style &&
          plan.metadata.style.indexOf('bt-recommended') !== -1
        ) {
          $scope.selected = /**@type {PlanObject}*/ plan;
        }

        // for each yearly plan find the plan with month interval
        if (plan.interval === 'year') {
          plan.opposite = plans.filter(function (item) {
            return item.name === plan.name && item.interval === 'month';
          })[0];
        }
      });

      if (plans.length === 0) {
        $scope.nothingToUpgrade = true;
        $scope.selected = null;
      } else if ($scope.selected === null) {
        $scope.selected = /**@type {PlanObject}*/ plans[0];
      }

      return plans;
    }

    /**
     * Sorts subscription plans.
     *
     * @private
     * @param {btPlanObject[]} plans - subscription plans
     * @return {btPlanObject[]}
     */
    function sortPlans(plans) {
      plans.sort(function (a, b) {
        var aLevel = a.metadata ? parseInt(a.metadata.level) : 0;
        var bLevel = b.metadata ? parseInt(b.metadata.level) : 0;

        return bLevel - aLevel;
      });

      return plans;
    }

    /**
     * Prepares subscription data and load plans.
     */
    function _prepareSubscriptionData() {
      $scope.recurring = gSubscription.recurring;
      $scope.trial = false;

      $scope.sub_name = btSubscriptionService.getName(gSubscription);

      if (gSubscription.amount && gSubscription.currency && gSubscription.interval) {
        $scope.sub_amount = gSubscription.amount / 100;
        $scope.sub_currency = gSubscription.currency.toUpperCase();
        $scope.sub_interval = gSubscription.interval.charAt(0).toUpperCase() + gSubscription.interval.slice(1);
      }

      if (gSubscription.recurring) {
        if (gSubscription.status === 'past_due') {
          $scope.endText = 'Last payment';
        } else {
          $scope.endText = 'Next payment';
        }
      }

      if (gSubscription.status === 'none' || gSubscription.status === 'free') {
        $scope.sub = 0;
      }

      if (gSubscription.status === 'trialing') {
        $scope.trial = true;
        $scope.sub = 1;
      }

      if ((gSubscription.status === 'active' || gSubscription.status === 'past_due') && gSubscription.amount) {
        $scope.sub = 2;
      }

      if (gSubscription.status === 'trialing' || gSubscription.status === 'special' || gSubscription.id === 'testing') {
        $scope.sub = 3;

        if (gSubscription.status === 'trialing') {
          $scope.trial = true;
        }
      }

      var data = btSubscriptionService.getDaysLeftObject(gSubscription, gUser);
      $scope.sub_due = btDateService.getFullFormattedDate(data.date);
      $scope.days_left = data.text;

      _preparePlans();
    }

    /**
     * Reacts on payment interval change.
     *
     * @param {*} newValue
     * @param {*} oldValue
     */
    function onPaymentIntervalChange(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }

      if (newValue) {
        $scope.payment.interval = 'year';
      } else {
        $scope.payment.interval = 'month';
      }

      $scope.plans.forEach(function (plan) {
        if (
          plan.interval === $scope.payment.interval &&
          plan.metadata.style &&
          plan.metadata.style.indexOf('bt-recommended') !== -1
        ) {
          $scope.selected = plan;
        }
      });
    }

    /**
     * This function restores user subscription.
     */
    function restorePurchases() {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Waiting...</p>' });

      btSubscriptionService
        .fixAppleSubscription()
        .then(function (status) {
          $ionicLoading.hide();
          if (status.code === btSubscriptionService.RestoreMessage.SUCCESS) {
            // Purchases were restored
            btToastrService.success(status.message, 'Restore Purchases', { type: btToastrService.ToastType.SETTINGS });
            $rootScope.scheduleAppRestart();
          } else {
            // Purchases were not restored due to some issue
            btToastrService.warning(
              status.message + ' If you should have a subscription please contact our support team.',
              'Restore Purchases',
              { type: btToastrService.ToastType.SETTINGS }
            );
          }
        })
        .catch(function (reason) {
          $ionicLoading.hide();
          btToastrService.error(reason.message, 'Restore Purchases', { type: btToastrService.ToastType.SETTINGS });
        });
    }
  }
})();
