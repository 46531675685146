/**
 * Created by Sergey Panpurin on 12/12/2016.
 */

// @ts-check
(function btPusherServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btPusherService:';

  /**
   * @ngdoc service
   * @name btPusherService
   * @memberOf btUtils
   * @description
   * This factory implements Pusher interface.
   *  initialized  - Initial state. No event is emitted in this state.
   *  connecting   - All dependencies have been loaded and Channels is trying to connect. The connection will also enter
   *                 this state when it is trying to reconnect after a connection failure.
   *  connected    - The connection to Channels is open and authenticated with your app.
   *  unavailable  - The connection is temporarily unavailable. In most cases this means that there is no internet
   *                 connection. It could also mean that Channels is down, or some intermediary is blocking the
   *                 connection. In this state, Channels will automatically retry the connection every ten seconds.
   *                 connecting_in events will still be triggered.
   *  failed       - Channels is not supported by the browser. This implies that WebSockets are not natively available
   *                 and an HTTP-based transport could not be found.
   *  disconnected - The Channels connection was previously connected and has now intentionally been closed.
   * @param {ecapp.ICustomRootScope} $rootScope -
   * @param {ecapp.ISettings} btSettings -
   */

  /** Separator */
  angular.module('btUtils').factory('btPusherService', btPusherService);

  btPusherService.$inject = ['$rootScope', '$window', 'btSettings', 'btEventEmitterService'];

  /**
   *
   * @param {ecapp.ICustomRootScope} $rootScope - ?
   * @param {angular.IWindowService} $window - window object service
   * @param {ecapp.ISettings} btSettings - ?
   * @param {ecapp.IEventEmitterService} btEventEmitterService - ?
   * @return {ecapp.IPusherService}
   */
  function btPusherService($rootScope, $window, btSettings, btEventEmitterService) {
    console.log('Running btPusherService');

    if ($rootScope.debug === undefined) {
      $rootScope.debug = {
        pusherRowsLog: [],
        pusherInsightsLog: [],
        pusherTradingInsightsLog: [],
      };
    }

    var gPusher;
    var gConnected = false;
    var gSharedSubscribed = false;
    var gPersonalSubscribed = false;

    var gCache = {};

    var gChannels = {
      marketAlerts: createPlaceholder('bt-market-alerts'),
      voiceAssistant: createPlaceholder('bt-voice-assistant'),
      rows: createPlaceholder('rowsChannel'),
      insights: createPlaceholder('insightsChannel'),
      tradingInsights: createPlaceholder('tradingInsightsChannel'),
      moments: createPlaceholder('tradeIdeasChannel'),
      personal: createPlaceholder('user'),
      dev: createPlaceholder('devChannel'),
    };

    btEventEmitterService.addListener('login:success', onLoginSuccess);
    btEventEmitterService.addListener('logout:success', onLogoutSuccess);

    initialize();

    return {
      addEventHandler: addEventHandler,
      removeEventHandler: removeEventHandler,
      getConnectionState: getConnectionState,
      channels: gChannels,
    };

    /**
     *
     * @alias ecapp.btPusherService#addEventHandler
     * @param {string} channel -
     * @param {string} event -
     * @param {function} handler -
     */
    function addEventHandler(channel, event, handler) {
      gChannels[channel].bind(event, handler);

      if ($window.isDevelopment) {
        gChannels[channel].bind('test-' + event, handler);
      }

      if ($window.isTesting || $window.isDevelopment) {
        gChannels[channel].bind('dev-' + event, handler);
      }
    }

    /**
     *
     * @alias ecapp.btPusherService#removeEventHandler
     * @param {string} channel -
     * @param {string} event -
     * @param {function} handler -
     */
    function removeEventHandler(channel, event, handler) {
      gChannels[channel].unbind(event, handler);

      if ($window.isDevelopment) {
        gChannels[channel].unbind('test-' + event, handler);
      }

      if ($window.isTesting || $window.isDevelopment) {
        gChannels[channel].unbind('dev-' + event, handler);
      }
    }

    /**
     * This function initialize Pusher client.
     */
    function initialize() {
      if (gConnected === false) {
        gConnected = true;
        if (btSettings.BT_PUSHER_ID) {
          gPusher = new window.Pusher(btSettings.BT_PUSHER_ID, { forceTLS: true });

          gPusher.connection.bind('connected', function () {
            if (gDebug) console.log(gPrefix, 'pusher is connected!');
          });

          gPusher.connection.bind('error', function (err) {
            console.error(gPrefix, err);
            if (err && err.error && err.error.data && err.error.data.code === 4004) {
              if (gDebug) console.log(gPrefix, 'detected limit error');
            }
          });

          gPusher.connection.bind('state_change', function (states) {
            if (gDebug)
              console.log(gPrefix, 'state was changed from "' + states.previous + '" to "' + states.current + '".');
          });

          activateSharedChannels();
          activatePersonalChannel();
        } else {
          console.error(gPrefix, "Can't found Pusher Application ID!");
        }
      } else {
        if (gDebug) console.log(gPrefix, 'pusher client is already connected!');
      }
    }

    /**
     * This function returns connection state.
     *
     * @return {string}
     */
    function getConnectionState() {
      if (gPusher) {
        return gPusher.connection.state;
      } else {
        return 'undefined';
      }
    }

    /**
     * This function reacts on user login.
     *
     * @param {*} data - (not used) event data
     */
    function onLoginSuccess(data) {
      void data;
      try {
        activatePersonalChannel();
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * This function reacts on user logout.
     *
     * @param {*} data - (not used) event data
     */
    function onLogoutSuccess(data) {
      void data;
      try {
        deactivatePersonalChannel();
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * This function returns user id or null.
     *
     * @return {?String} - user identifier
     */
    function getUserId() {
      if ($rootScope.currentUser && $rootScope.currentUser.id) {
        return $rootScope.currentUser.id;
      } else {
        return null;
      }
    }

    /**
     * This function creates a placeholder for channel object.
     *
     * @param {String} channel - channel name
     * @return {ecapp.IPusherChannel}
     */
    function createPlaceholder(channel) {
      if (gCache[channel] === undefined) {
        gCache[channel] = {};
      }

      return {
        bind: function (event, callback) {
          console.error(gPrefix, 'default bind function of', channel, event, 'was called');
          gCache[channel][event] = callback;
        },
        unbind: function (event, callback) {
          console.error(gPrefix, 'default unbind function of', channel, event, 'was called');
          gCache[channel][event] = callback;
        },
      };
    }

    /**
     * This function activates personal channel.
     */
    function activatePersonalChannel() {
      if (!gPersonalSubscribed) {
        if (getUserId() !== null) {
          gPersonalSubscribed = true;
          gChannels.personal = gPusher.subscribe('user-' + getUserId());
          gChannels.personal.bind('echo', handleEcho);
        }
      }
    }

    /**
     * This function deactivates personal channel.
     */
    function deactivatePersonalChannel() {
      if (gPersonalSubscribed) {
        gPersonalSubscribed = false;
        gChannels.personal.unbind();
        gPusher.unsubscribe('user-' + getUserId());
        gChannels.personal = createPlaceholder('user');
      }
    }

    /**
     * This function activates shared channels.
     */
    function activateSharedChannels() {
      if (gSharedSubscribed === false) {
        gSharedSubscribed = true;
        gChannels.marketAlerts = gPusher.subscribe('bt-market-alerts');
        gChannels.voiceAssistant = gPusher.subscribe('bt-voice-assistant');
        gChannels.rows = gPusher.subscribe('rowsChannel');
        gChannels.insights = gPusher.subscribe('insightsChannel');
        gChannels.tradingInsights = gPusher.subscribe('tradingInsightsChannel');
        gChannels.moments = gPusher.subscribe('tradeIdeasChannel');
        gChannels.dev = gPusher.subscribe('devChannel');

        bindEchoHandlers();
        bindDevHandler();
      }
    }

    /**
     * This function binds echo handler to all channels.
     */
    function bindEchoHandlers() {
      gChannels.marketAlerts.bind('update-echo', handleEcho);
      gChannels.voiceAssistant.bind('update-echo', handleEcho);
      gChannels.rows.bind('update-echo', handleEcho);
      gChannels.insights.bind('update-echo', handleEcho);
      gChannels.tradingInsights.bind('update-echo', handleEcho);
      gChannels.moments.bind('update-echo', handleEcho);
      gChannels.dev.bind('update-echo', handleEcho);
    }

    /**
     * This function binds handler to dev channel.
     */
    function bindDevHandler() {
      gChannels.dev.bind('update', handleDev);
    }

    /**
     * This function is a echo handler.
     *
     * @param {*} data - pusher message
     */
    function handleEcho(data) {
      if (gDebug) console.log(gPrefix, 'new pusher message:', data);
    }

    /**
     * This function is a handler using in development process.
     *
     * @param {*} data - pusher message
     */
    function handleDev(data) {
      if (gDebug) console.log(gPrefix, 'new dev message:', data);
    }
  }
})();
